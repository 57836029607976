import React from 'react'

import '../assets/css/welcome-letter.css'
import LetterHeader from '../assets/img/welcome-letter-header.jpg'

const WelcomeLetter = (props) => {

    return (
        <div className="WordSection1">
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}><img width="100%" id="Picture 4" src={LetterHeader} alt="Logo, company name
      
      Description automatically generated" /></span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>Elite
            Service Warranty, LLC</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>932 South
            Stapley DR</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>Mesa, AZ&nbsp;
            85206</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>{props.customer.name}</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>{props.customer.shippingStreetAddress}</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>{props.customer.shippingStreetAddress2}</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>{props.customer.shippingCity},&nbsp;{props.customer.shippingState}&nbsp;{props.customer.shippingZip}</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>Dear {props.customer.name}:</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><a name="_heading=h.gjdgxs" /><span style={{fontFamily: '"Times New Roman",serif'}}>It is with great pleasure that we welcome you to the
            Elite Service Warranty Family.&nbsp; This letter represents your service warranty
            application’s approval for coverage regarding your Recreational Vehicle (RV).&nbsp;
            Enclosed with this letter is an official contract presenting the terms and
            conditions of coverage that is afforded to you with your service warranty.</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>Elite
            Service Warranty, LLC is founded by RV enthusiasts. We understand the value
            that our RVs bring to our families and recreational pursuits.&nbsp; We are
            determined to provide unparalleled customer service to keep your RV
            operational, on the road, and in the field. We only partner with exceptional RV
            service and maintenance technicians that can expedite and troubleshoot any
            issues that you might experience with your prized investment.&nbsp; With your
            Elite Service Warranty, you receive 24 hours a day, seven days a week attention
            to any of your claim needs.&nbsp;&nbsp;</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}><br />
            As issues arise, please contact (866) 862-7164, in order to speak with someone
            directly.&nbsp; You may also email </span><a href="mailto:customersupport@eliteservicewarranty.com"><span style={{fontFamily: '"Times New Roman",serif', color: '#0563C1'}}>customersupport@eliteservicewarranty.com</span></a><span style={{fontFamily: '"Times New Roman",serif'}}>.&nbsp; Thank you again for your
            purchase, and we wish you all the best as you enjoy all of the freedoms that an
            RV provides.</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>Sincerely,</span></p>

        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>The Elite
            Service Warranty Team</span></p>
        <p className="MsoNormal"><span style={{fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></p>
      </div>
      
    )

}

export default WelcomeLetter