import LandingPage from "./pages/landing/Landing2";
import CoveragePage from "./pages/coverage/Coverage";
import ContactPage from "./pages/contact";

import AppStylesWrapper from "./layouts/AppStyles";
import LandingStylesWrapper from "./layouts/LandingStyles";

import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import AdminDashboardLayout from "./layouts/AdminDashboard";
import CustomerDashboardLayout from "./layouts/CustomerDashboard";

import AdminSignIn from "./pages/admin/auth/SignIn";
import AdminResetPassword from "./pages/admin/auth/ResetPassword";
import AdminDealers from "./pages/admin/portal/DealerList";
import AdminCustomers from "./pages/admin/portal/CustomerList";
import AdminCustomerDetail from "./pages/admin/portal/CustomerDetail";
import AdminWelcomeLetters from "./pages/admin/portal/WelcomeLetters";

import CustomerLookup from "./pages/customer/auth/Lookup";
import CustomerDetails from "./pages/customer/portal/CustomerDetail";

import DealerSignIn from "./pages/dealer/auth/SignIn";
import DealerResetPassword from "./pages/dealer/auth/ResetPassword";
import DealerCustomerList from "./pages/dealer/portal/CustomerList";
import DealerCustomerDetail from "./pages/dealer/portal/CustomerDetail";
import DealerNewContract from "./pages/dealer/portal/NewContract";
import PrintCustomerDetail from "./pages/dealer/portal/PrintCustomerDetails";

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { ToastProvider } from "react-toast-notifications";

import { AppContextProvider } from "./State";

import "bootstrap/dist/css/bootstrap.min.css";
// import './assets/css/bootstrap/scss/bootstrap.min.css'

import "./assets/css/corporate.css";
import "./assets/css/landing.css";

import "./App.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

function App() {
  return (
    <AppContextProvider>
      <ToastProvider autoDismiss autoDismissTimeout={5000}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <LandingStylesWrapper>
                  <LandingPage {...props} />
                </LandingStylesWrapper>
              )}
            />
            <Route
              exact
              path="/coverage"
              render={(props) => (
                <LandingStylesWrapper>
                  <CoveragePage {...props} />
                </LandingStylesWrapper>
              )}
            />
            <Route
              exact
              path="/contact"
              render={(props) => (
                <LandingStylesWrapper>
                  <ContactPage {...props} />
                </LandingStylesWrapper>
              )}
            />
            <Route
              path="/admin/signin"
              render={(props) => (
                <AppStylesWrapper>
                  <AuthLayout>
                    <AdminSignIn {...props} />
                  </AuthLayout>
                </AppStylesWrapper>
              )}
            />
            <Route
              path="/admin/reset-password"
              render={(props) => (
                <AppStylesWrapper>
                  <AuthLayout>
                    <AdminResetPassword {...props} />
                  </AuthLayout>
                </AppStylesWrapper>
              )}
            />
            <Route
              path="/admin/dealers"
              render={(props) => (
                <AppStylesWrapper>
                  <AdminDashboardLayout {...props}>
                    <AdminDealers {...props} />
                  </AdminDashboardLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/admin/customers"
              render={(props) => (
                <AppStylesWrapper>
                  <AdminDashboardLayout {...props}>
                    <AdminCustomers {...props} />
                  </AdminDashboardLayout>
                </AppStylesWrapper>
              )}
              exact
            />

            <Route
              path="/admin/customers/:id"
              render={(props) => (
                <AppStylesWrapper>
                  <AdminDashboardLayout {...props}>
                    <AdminCustomerDetail {...props} />
                  </AdminDashboardLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/admin/welcomeletters"
              render={(props) => (
                <AppStylesWrapper>
                  <AdminDashboardLayout {...props}>
                    <AdminWelcomeLetters {...props} />
                  </AdminDashboardLayout>
                </AppStylesWrapper>
              )}
              exact
            />

            <Route
              path="/customer/lookup"
              render={(props) => (
                <AppStylesWrapper>
                  <AuthLayout>
                    <CustomerLookup {...props} />
                  </AuthLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/customer/details/:vin"
              render={(props) => (
                <AppStylesWrapper>
                  <CustomerDashboardLayout>
                    <CustomerDetails {...props} />
                  </CustomerDashboardLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/dealer/signin"
              render={(props) => (
                <AppStylesWrapper>
                  <AuthLayout>
                    <DealerSignIn {...props} />
                  </AuthLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/dealer/reset-password"
              render={(props) => (
                <AppStylesWrapper>
                  <AuthLayout>
                    <DealerResetPassword {...props} />
                  </AuthLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/dealer/new-contract"
              render={(props) => (
                <AppStylesWrapper>
                  <DashboardLayout {...props}>
                    <DealerNewContract {...props} />
                  </DashboardLayout>
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/dealer/customers"
              render={(props) => (
                <AppStylesWrapper>
                  <DashboardLayout {...props}>
                    <DealerCustomerList {...props} />
                  </DashboardLayout>
                </AppStylesWrapper>
              )}
              exact
            />

            <Route
              path="/dealer/customers/:id/print"
              render={(props) => (
                <AppStylesWrapper>
                  <PrintCustomerDetail {...props} />
                </AppStylesWrapper>
              )}
            />

            <Route
              path="/dealer/customers/:id"
              render={(props) => (
                <AppStylesWrapper>
                  <DashboardLayout {...props}>
                    <DealerCustomerDetail {...props} />
                  </DashboardLayout>
                </AppStylesWrapper>
              )}
            />

            <Redirect path="/dealer" to="/dealer/customers" />
            <Redirect path="/dealer/dashboard" to="/dealer/customers" />
            <Redirect path="/admin/dashboard" to="/admin/customers" />
            <Redirect path="/admin" to="/admin/customers" />
            <Redirect path="/customer/signin" to="/" />

            <Redirect path="/" to="/" />
            {/*<Route path='/dealer/claims'>
          <DealerContracts />
        </Route>
        <Route path='/dealer/portal'>
          <Redirect to='/dealer/contracts' />
        </Route>
        <Route path='/dealer/contracts'>
          <DealerContracts />
        </Route>
        <Route path='/dealer/newcontract'>
          <DealerNewContract />
        </Route>
        <Route path='/customer/signin'>
          <CustomerSignInPage />
        </Route>
        <Route path='/customer/portal'>
          <CustomerPortal />
        </Route> */}
          </Switch>
        </Router>
      </ToastProvider>
    </AppContextProvider>
  );
}

export default App;
