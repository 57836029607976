export const listCustomersNoSignatures = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
