import { Auth, API } from 'aws-amplify'

import aws_exports from '../../aws-exports'

export default class AdminService {
  constructor() {}

  createUser = async (username, attributes) => {
    let apiName = 'AdminQueries'
    let path = '/createUser'
    let myInit = {
      body: {
        username: username,
        groupname: 'Dealers',
        attributes: attributes
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.post(apiName, path, myInit)
  }

  resetUser = async (username) => {
    let apiName = 'AdminQueries'
    let path = '/resetUser'
    let myInit = {
      body: {
        username: username
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.post(apiName, path, myInit)
  }

  updateUser = async (username, attributes) => {
    let apiName = 'AdminQueries'
    let path = '/updateUser'
    let myInit = {
      body: {
        username: username,
        attributes: attributes
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.post(apiName, path, myInit)
  }

  addUserToGroup = async (username, groupname) => {
    let apiName = 'AdminQueries'
    let path = '/addUserToGroup'
    let myInit = {
      body: {
        username: username,
        groupname: groupname
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    }
    return await API.post(apiName, path, myInit)
  }

  // listUsers = async () => {
  //   let apiName = 'AdminQueries'
  //   let path = '/listUsers'
  //   let myInit = {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
  //     }
  //   }
  //   return await API.get(apiName, path, myInit)
  // }
}
