/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      applicationNumber
      phone
      email
      shippingStreetAddress
      shippingStreetAddress2
      shippingCity
      shippingState
      shippingZip
      notes {
        items {
          id
          note
          files
          author
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      welcomeLetterSent
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      applicationNumber
      phone
      email
      shippingStreetAddress
      shippingStreetAddress2
      shippingCity
      shippingState
      shippingZip
      notes {
        items {
          id
          note
          files
          author
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      welcomeLetterSent
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      applicationNumber
      phone
      email
      shippingStreetAddress
      shippingStreetAddress2
      shippingCity
      shippingState
      shippingZip
      notes {
        items {
          id
          note
          files
          author
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      welcomeLetterSent
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerNote = /* GraphQL */ `
  mutation CreateCustomerNote(
    $input: CreateCustomerNoteInput!
    $condition: ModelCustomerNoteConditionInput
  ) {
    createCustomerNote(input: $input, condition: $condition) {
      id
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      note
      files
      author
      authorID
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerNote = /* GraphQL */ `
  mutation UpdateCustomerNote(
    $input: UpdateCustomerNoteInput!
    $condition: ModelCustomerNoteConditionInput
  ) {
    updateCustomerNote(input: $input, condition: $condition) {
      id
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      note
      files
      author
      authorID
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerNote = /* GraphQL */ `
  mutation DeleteCustomerNote(
    $input: DeleteCustomerNoteInput!
    $condition: ModelCustomerNoteConditionInput
  ) {
    deleteCustomerNote(input: $input, condition: $condition) {
      id
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      note
      files
      author
      authorID
      createdAt
      updatedAt
    }
  }
`;
export const createDealer = /* GraphQL */ `
  mutation CreateDealer(
    $input: CreateDealerInput!
    $condition: ModelDealerConditionInput
  ) {
    createDealer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      contact_person
      contracts {
        items {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDealer = /* GraphQL */ `
  mutation UpdateDealer(
    $input: UpdateDealerInput!
    $condition: ModelDealerConditionInput
  ) {
    updateDealer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      contact_person
      contracts {
        items {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDealer = /* GraphQL */ `
  mutation DeleteDealer(
    $input: DeleteDealerInput!
    $condition: ModelDealerConditionInput
  ) {
    deleteDealer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      contact_person
      contracts {
        items {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      id
      VIN
      style
      make
      vehicleModel
      chassisModel
      vehicleYear
      purchasePrice
      purchaseDate
      lienholder
      owner {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      claims {
        items {
          id
          approved
          reviewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      id
      VIN
      style
      make
      vehicleModel
      chassisModel
      vehicleYear
      purchasePrice
      purchaseDate
      lienholder
      owner {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      claims {
        items {
          id
          approved
          reviewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      id
      VIN
      style
      make
      vehicleModel
      chassisModel
      vehicleYear
      purchasePrice
      purchaseDate
      lienholder
      owner {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      contract {
        id
        dealer {
          id
          name
          email
          phone
          contact_person
          createdAt
          updatedAt
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        customer {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        typeNew
        tier
        termLength
        deductible
        contractPrice
        seniorCitizenMilitary
        originalInServiceDate
        originalWarrantyMonths
        reviewed
        approved
        customerSignature
        dealerSignature
        createdAt
        updatedAt
      }
      claims {
        items {
          id
          approved
          reviewed
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      dealer {
        id
        name
        email
        phone
        contact_person
        contracts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      typeNew
      tier
      termLength
      deductible
      contractPrice
      seniorCitizenMilitary
      originalInServiceDate
      originalWarrantyMonths
      reviewed
      approved
      customerSignature
      dealerSignature
      createdAt
      updatedAt
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      dealer {
        id
        name
        email
        phone
        contact_person
        contracts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      typeNew
      tier
      termLength
      deductible
      contractPrice
      seniorCitizenMilitary
      originalInServiceDate
      originalWarrantyMonths
      reviewed
      approved
      customerSignature
      dealerSignature
      createdAt
      updatedAt
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      dealer {
        id
        name
        email
        phone
        contact_person
        contracts {
          nextToken
        }
        createdAt
        updatedAt
      }
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      customer {
        id
        name
        applicationNumber
        phone
        email
        shippingStreetAddress
        shippingStreetAddress2
        shippingCity
        shippingState
        shippingZip
        notes {
          nextToken
        }
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        welcomeLetterSent
        createdAt
        updatedAt
      }
      typeNew
      tier
      termLength
      deductible
      contractPrice
      seniorCitizenMilitary
      originalInServiceDate
      originalWarrantyMonths
      reviewed
      approved
      customerSignature
      dealerSignature
      createdAt
      updatedAt
    }
  }
`;
export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      id
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      approved
      reviewed
      notes {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      id
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      approved
      reviewed
      notes {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      id
      vehicle {
        id
        VIN
        style
        make
        vehicleModel
        chassisModel
        vehicleYear
        purchasePrice
        purchaseDate
        lienholder
        owner {
          id
          name
          applicationNumber
          phone
          email
          shippingStreetAddress
          shippingStreetAddress2
          shippingCity
          shippingState
          shippingZip
          welcomeLetterSent
          createdAt
          updatedAt
        }
        contract {
          id
          typeNew
          tier
          termLength
          deductible
          contractPrice
          seniorCitizenMilitary
          originalInServiceDate
          originalWarrantyMonths
          reviewed
          approved
          customerSignature
          dealerSignature
          createdAt
          updatedAt
        }
        claims {
          nextToken
        }
        createdAt
        updatedAt
      }
      approved
      reviewed
      notes {
        items {
          id
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createClaimNote = /* GraphQL */ `
  mutation CreateClaimNote(
    $input: CreateClaimNoteInput!
    $condition: ModelClaimNoteConditionInput
  ) {
    createClaimNote(input: $input, condition: $condition) {
      id
      claim {
        id
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        approved
        reviewed
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const updateClaimNote = /* GraphQL */ `
  mutation UpdateClaimNote(
    $input: UpdateClaimNoteInput!
    $condition: ModelClaimNoteConditionInput
  ) {
    updateClaimNote(input: $input, condition: $condition) {
      id
      claim {
        id
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        approved
        reviewed
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
export const deleteClaimNote = /* GraphQL */ `
  mutation DeleteClaimNote(
    $input: DeleteClaimNoteInput!
    $condition: ModelClaimNoteConditionInput
  ) {
    deleteClaimNote(input: $input, condition: $condition) {
      id
      claim {
        id
        vehicle {
          id
          VIN
          style
          make
          vehicleModel
          chassisModel
          vehicleYear
          purchasePrice
          purchaseDate
          lienholder
          createdAt
          updatedAt
        }
        approved
        reviewed
        notes {
          nextToken
        }
        createdAt
        updatedAt
      }
      note
      createdAt
      updatedAt
    }
  }
`;
