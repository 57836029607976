import React, { useState } from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

import logo from "../../assets/img/esw-logo.png";
import heroBackground from "../../assets/img/herorv.jpg";

const cx = classNames; //.bind(styles)

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="LandingPage">
        <div>
          {/* NAVBAR
    ================================================== */}
          <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
            <div className="container">
              {/* Brand */}
              <a className="navbar-brand" href="./index.html">
                <img src={logo} className="navbar-brand-img" alt="..." />
                <span className="navbar-brand-text">
                  Elite Service Warranty
                </span>
              </a>
              {/* Toggler */}
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink href="/coverage">Coverage</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dealer/signin">Dealers</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#contact">Contact Us</NavLink>
                  </NavItem>
                </Nav>
                <Link to="/customer/lookup" className="ml-auto">
                  <a className="navbar-btn btn btn-sm btn-primary lift">
                    Check my warranty
                  </a>
                </Link>
              </Collapse>
              {/* Collapse */}
            </div>
          </nav>

          {/* WELCOME
    ================================================== */}
          <section>
            {/* Content */}
            <div className="container d-flex flex-column">
              <div className="row align-items-center justify-content-between no-gutters min-vh-100">
                <div className="col-12 col-md-5 align-self-stretch">
                  {/* Image (mobile) */}
                  <img
                    src="assets/img/photos/photo-5.jpg"
                    className="d-md-none img-cover"
                    alt="..."
                  />
                  {/* Image */}
                  <div
                    className="d-none d-md-block vw-50 h-100 float-right bg-cover"
                    style={{
                      backgroundImage: "url(assets/img/photos/contact.jpg)",
                    }}
                  />
                </div>
                <div className="col-12 col-md-6 py-8 py-md-11">
                  {/* Heading */}
                  <h2 className="font-weight-bold text-center mb-2">
                    Have a Question?
                  </h2>
                  {/* Text */}
                  <p className="font-size-lg text-center text-muted mb-7 mb-md-9">
                    Give us a call at 866-862-7164 or send us an email at{" "}
                    <a href="mailto:customerservice@eliteservicewarranty.com">
                      customerservice@eliteservicewarranty.com
                    </a>
                  </p>

                  {/* Divider */}
                  <hr className="hr-sm my-6 my-md-8 border-gray-300" />
                  {/* Form */}
                </div>
              </div>{" "}
              {/* / .row */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
