import React from 'react'

import { Row, Col, Collapse, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem, Form, Input } from 'reactstrap'

import { AlertCircle, Bell, BellOff, Home, MessageCircle, PieChart, Settings, User, UserPlus } from 'react-feather'

import { AppContext } from '../State'

import { Auth } from 'aws-amplify'

import usFlag from '../assets/img/flags/us.png'
import esFlag from '../assets/img/flags/es.png'
import deFlag from '../assets/img/flags/de.png'
import nlFlag from '../assets/img/flags/nl.png'

import avatar1 from '../assets/img/avatars/avatar.jpg'
import avatar3 from '../assets/img/avatars/avatar-3.jpg'
import avatar4 from '../assets/img/avatars/avatar-4.jpg'
import avatar5 from '../assets/img/avatars/avatar-5.jpg'
import { useContext } from 'react'

const NavbarDropdown = ({ children, count, showBadge, header, footer, icon: Icon }) => (
  <UncontrolledDropdown nav inNavbar className='mr-2'>
    <DropdownToggle nav className='nav-icon dropdown-toggle'>
      <div className='position-relative'>
        <Icon className='align-middle' size={18} />
        {showBadge ? <span className='indicator'>{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className='dropdown-menu-lg py-0'>
      <div className='dropdown-menu-header position-relative'>
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className='dropdown-menu-footer'>
        <span className='text-muted'>{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
)

const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className='align-items-center'>
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? 'pl-2' : null}>
        <div className='text-dark'>{title}</div>
        <div className='text-muted small mt-1'>{description}</div>
        <div className='text-muted small mt-1'>{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
)

const NavbarComponent = (props) => {
  const { state, dispatch } = useContext(AppContext)

  const signOut = async () => {
    await Auth.signOut()
    dispatch({
      type: 'setAuthenticated',
      value: false,
      user: null,
      userGroup: null
    })
  }

  return (
    <Navbar color='white' light expand>
      <span
        className='sidebar-toggle d-flex mr-2'
        onClick={() => {
          dispatch({
            type: 'toggleSidebar'
          })
        }}>
        <i className='hamburger align-self-center' />
      </span>

      <Collapse navbar>
        <Nav className='ml-auto' navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className='d-inline-block d-sm-none'>
              <DropdownToggle nav caret>
                <Settings size={18} className='align-middle' />
              </DropdownToggle>
            </span>
            <span className='d-none d-sm-inline-block'>
              <DropdownToggle nav caret>
                <span className='text-dark'>{state?.user?.attributes?.email}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              <DropdownItem onClick={signOut}>Sign out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default NavbarComponent
