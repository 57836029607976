import React, { useEffect, useState } from "react";
import Amplify, { Auth, API, Storage } from "aws-amplify";
import * as queries from "../../../graphql/queries";
import Pdf from "react-to-pdf";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import moment from "moment";
import cs from "classnames";

import logo from "../../../assets/img/esw-logo.png";

const FormCell = ({ title, value, expandable }) => {
  return (
    <div class={cs([expandable ? "col-auto" : "col", "border p-1"])}>
      <div className="d-flex flex-column">
        <small className="text-muted text-small">{title}</small>
        <div className="">{value}</div>
      </div>
    </div>
  );
};

const FormHeaderCell = ({ title }) => {
  return (
    <div className="col d-flex align-items-center justify-content-center text-center p-1 bg-dark text-light">
      {title}
    </div>
  );
};

const ContractToPrint = (props) => {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [didDownload, setDidDownload] = useState(false);

  const pullCustomer = async (customer_id) => {
    const res = await API.graphql({
      query: queries.getCustomer,
      variables: { id: customer_id },
    });
    const customer = res.data.getCustomer;
    setSelectedCustomer(customer);
  };

  useEffect(() => {
    const customer_id = props.match.params.id;
    pullCustomer(customer_id);
  }, []);

  const triggerDownload = (toPdf) => {
    if (didDownload === false) {
      toPdf();
      setDidDownload(true);
    }
  };

  if (!selectedCustomer) {
    return <></>;
  }

  return (
    <Pdf filename={`${selectedCustomer?.name}-Contract.pdf`}>
      {({ toPdf, targetRef }) => (
        <div style={{ zIndex: -500 }} onLoad={() => triggerDownload(toPdf)}>
          <div
            ref={targetRef}
            style={{
              width: "8.5in",
              height: "11in",
              backgroundColor: "white",
              padding: "1in",

              justifyContent: "center",
              paddingLeft: "-0.3in",
              paddingTop: "0.3in",
              paddingBottom: "0.3in",
            }}
          >
            <div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={logo} height={100} />
                <h3 classNam="m-0">
                  Extended Service Warranty
                  <br /> for Recreational Vehicles
                </h3>
              </div>

              <div class="container border mt-3 w-100">
                <Row>
                  <FormCell
                    title="Seller/Dealer Name"
                    value={selectedCustomer?.contract.dealer?.name}
                  />
                  <FormCell
                    title="Application Date"
                    value={moment(selectedCustomer?.contract.createdAt).format(
                      "MMM DD, YYYY"
                    )}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Customer Name"
                    value={selectedCustomer?.name}
                  />
                  <FormCell
                    title="Phone"
                    value={selectedCustomer?.phone}
                    expandable
                  />
                  <FormCell
                    title="Email"
                    value={selectedCustomer?.email}
                    expandable
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Shipping Address"
                    value={
                      selectedCustomer?.shippingStreetAddress +
                      " " +
                      selectedCustomer?.shippingStreetAddress2
                    }
                    expandable
                  />
                  <FormCell
                    title="City"
                    value={selectedCustomer?.shippingCity}
                  />
                  <FormCell
                    title="State"
                    value={selectedCustomer?.shippingState}
                  />
                  <FormCell title="Zip" value={selectedCustomer?.shippingZip} />
                </Row>
                <Row>
                  <FormHeaderCell title="Recreational Vehicle Information" />
                </Row>
                <Row>
                  <FormCell
                    title="Recreational Vehicle Type"
                    value={selectedCustomer?.vehicle.style}
                  />
                  <FormCell
                    title="Vehicle Year"
                    value={selectedCustomer?.vehicle.vehicleYear}
                  />
                  <FormCell
                    title="Vehicle Make"
                    value={selectedCustomer?.vehicle.make}
                    expandable
                  />
                  <FormCell
                    title="Vehicle Model"
                    value={selectedCustomer?.vehicle.vehicleModel}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Chassis Model"
                    value={selectedCustomer?.vehicle.chassisModel}
                  />
                  <FormCell
                    title="Vehicle VIN"
                    value={selectedCustomer?.vehicle.VIN}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Purchase Price"
                    value={"$" + selectedCustomer?.vehicle.purchasePrice}
                  />
                  <FormCell
                    title="Purchase Date"
                    value={selectedCustomer?.vehicle.purchaseDate}
                    expandable
                  />
                  <FormCell
                    title="Lienholder"
                    value={selectedCustomer?.vehicle.lienholder}
                  />
                </Row>
                <Row>
                  <FormHeaderCell title="Service Warranty Contract Information and Options" />
                </Row>
                <Row>
                  <FormCell
                    title="Contract"
                    value={selectedCustomer?.contract.tier}
                  />
                  <FormCell
                    title="Vehicle New/Used"
                    value={
                      (selectedCustomer?.contract.typeNew ? "New" : "Used") +
                      " vehicle"
                    }
                  />
                  <FormCell
                    title="Deductible"
                    value={"$" + selectedCustomer?.contract.deductible}
                  />
                </Row>
                <Row>
                  <FormCell
                    title="Term length"
                    value={selectedCustomer?.contract.termLength + " months"}
                  />
                  <FormCell
                    title="Service contract price"
                    value={"$" + selectedCustomer?.contract.contractPrice}
                  />

                  <FormCell
                    title="Senior Citizen/Military"
                    value={
                      selectedCustomer?.contract.seniorCitizenMilitary
                        ? "Yes"
                        : "No"
                    }
                  />
                </Row>
                <Row>
                  <FormHeaderCell title="Warranty Information" />
                </Row>
                <Row>
                  <FormCell
                    title="Original In-Service Date"
                    value={selectedCustomer?.contract.originalInServiceDate}
                  />
                  <FormCell
                    title="Original Warranty Length"
                    value={
                      selectedCustomer?.contract.originalWarrantyMonths +
                      " months"
                    }
                  />
                </Row>
              </div>

              <small className="text-muted">
                Customer has reviewed a copy of the Service Warranty Contract.
                Customer also understands that this is an application for
                coverage; coverage is not legally binding or enforced until
                approved by Elite Service Warranty, LLC. We reserve the right to
                reject applications whatsoever. When approved, we will issue a
                registration page and contract to the customer. If you do not
                receive the registration page within sixty (60) days, you must
                call us at (866) 862-7164, or email us at
                customerservice@eliteservicewarranty.com or write to us 932
                South Stapley DR, Mesa, AZ 85204. Financing the vehicle does not
                require the purchase of a service warranty contract.
              </small>

              <div className="d-flex align-items-center">
                <div>Customer Signature: </div>
                <div>
                  <img
                    src={selectedCustomer?.contract.customerSignature}
                    style={{ maxHeight: "100px" }}
                  />
                </div>
                <div>
                  Date: &nbsp;
                  {moment(selectedCustomer?.contract.createdAt).format(
                    "MMM DD, YYYY"
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>Dealer Signature: </div>
                <div>
                  <img
                    src={selectedCustomer?.contract.dealerSignature}
                    style={{ maxHeight: "100px" }}
                  />
                </div>
                <div>
                  Date: &nbsp;
                  {moment(selectedCustomer?.contract.createdAt).format(
                    "MMM DD, YYYY"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Pdf>
  );
};

export default ContractToPrint;
