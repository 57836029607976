import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'

import * as queries from '../../../graphql/queries'

import { Button, Card, CardBody, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap'

import { AppContext } from '../../../State'

import AdminService from '../../../services/admin'

import LoadingButton from '../../../components/LoadingButton'

const SignIn = ({ history }) => {
  const { state, dispatch } = useContext(AppContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [requiresNewPassword, setRequiresNewPassword] = useState(false)

  useEffect(() => {
    if (state.userGroup == 'Dealers') {
      history.replace('/dealer/dashboard')
    }
  }, [state.user])

  async function attemptSignin() {
    setIsLoading(true)
    setError('')
    try {
      const user = await Auth.signIn(email, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setRequiresNewPassword(true)
      } else {
        if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'Dealers') {
          throw new Error('You are not a dealer. Please login with a dealer account here.')
        }

        const { dealerId, dealerName } = await getDealerIDFromEmail(user.signInUserSession.idToken.payload.email)

        dispatch({
          type: 'setAuthenticated',
          value: true,
          user: user,
          userGroup: user.signInUserSession.idToken.payload['cognito:groups'][0],
          dealerId: dealerId,
          dealerName: dealerName
        })
        history.replace('/dealer/dashboard')
      }
    } catch (error) {
      console.log('error signing in', error)
      if (error.message === 'Temporary password has expired and must be reset by an administrator.') {
        await AdminService.resetUser(email)
        setError('Your temporary password has expired. Please check your email for a new password.')
      } else {
        setError(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getDealerIDFromEmail = async (email) => {
    const dealerFilter = {
      email: {
        eq: email
      }
    }
    const res = await API.graphql({ query: queries.listDealers, variables: { filter: dealerFilter } })
    const dealerId = res.data.listDealers.items[0].id
    const dealerName = res.data.listDealers.items[0].name
    return { dealerId, dealerName }
  }

  async function setNewPasswordFunc() {
    setIsLoading(true)
    setError('')
    Auth.signIn(email, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
          )
            .then(async (user) => {
              const { dealerId, dealerName } = await getDealerIDFromEmail(user.signInUserSession.idToken.payload.email)

              // at this time the user is logged in if no MFA required
              dispatch({
                type: 'setAuthenticated',
                value: true,
                user: user,
                userGroup: user.signInUserSession.idToken.payload['cognito:groups'][0],
                dealerId: dealerId,
                dealerName: dealerName
              })
            })
            .catch((error) => {
              setError(error.message)
              console.log(error)
            })
            .finally(() => {
              setIsLoading(false)
            })
        } else {
          // other situations
        }
      })
      .catch((e) => {
        console.log(e)
        setError(e.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (requiresNewPassword) {
    return (
      <React.Fragment>
        <div className='text-center mt-4'>
          <h1 className='h2'>Set a new password</h1>
          <p className='lead'>Please enter a new password for your account</p>
        </div>

        <Card>
          <CardBody>
            <div className='m-sm-4'>
              <Form>
                <FormGroup>
                  <Label>Password</Label>
                  <Input bsSize='lg' type='password' placeholder='New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </FormGroup>
                <div className='text-center mt-3'>
                  <div className='text-danger mb-2'>{error}</div>
                  <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading} onClick={setNewPasswordFunc}>
                    Set password
                  </LoadingButton>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className='text-center mt-4'>
        <h2>Dealer Login</h2>
      </div>

      <Card>
        <CardBody>
          <div className='m-sm-4'>
            <div className='text-center'></div>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input bsSize='lg' type='email' placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input bsSize='lg' type='password' placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                <small>
                  <Link to='/dealer/reset-password'>Forgot password?</Link>
                </small>
              </FormGroup>

              <div className='text-center mt-3'>
                <div className='text-danger mb-2'>{error}</div>
                <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading} onClick={attemptSignin}>
                  Login
                </LoadingButton>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SignIn
