import React, { useRef } from 'react'
import { useState, useEffect, useContext } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap'

import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'
import Amplify from '@aws-amplify/core'
import * as mutations from '../../../graphql/mutations'
import * as queries from '../../../graphql/queries'

import SignatureCanvas from 'react-signature-canvas'

import { AppContext } from '../../../State'
import API from '@aws-amplify/api'

import LoadingButton from '../../../components/LoadingButton'

import Rollbar from 'rollbar'
const rollbar = new Rollbar({
  accessToken: 'dd58ad5a994d42aeac42eb02c0510aac',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production'
  }
})

const NewContract = (props) => {
  const { state, dispatch } = useContext(AppContext)

  const [deductibleOptions, setDeductibleOptions] = useState([])
  const [deductible, setDeductible] = useState()
  const [warrantyTier, setWarrantyTier] = useState('Elite Elect')

  const [readyForSignatures, setReadyForSignatures] = useState(false)
  const [formComplete, setFormComplete] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const customerSignatureRef = useRef()
  const dealerSignatureRef = useRef()

  useEffect(() => {
    let deductibleOpts = []
    if (warrantyTier === 'Elite Elect') {
      deductibleOpts = ['$100', '$200', '$500']
    } else {
      deductibleOpts = ['$100', '$200', '$500']
    }
    setDeductibleOptions(deductibleOpts)
    setDeductible(deductibleOpts[0])
  }, [warrantyTier])

  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const moveToSignatures = (event, errors, values) => {
    setFormValues(values)
    setFormErrors(errors)
    console.log(values)
    if (errors.length > 0) {
      setErrorMessage(`There are ${errors.length} errors in your application. Please fix them and try again.`)
      // setReadyForSignatures(true) //// REMOVE
    } else {
      setErrorMessage('')
      setReadyForSignatures(true)
    }
  }

  const submitApplication = async () => {
    setIsLoading(true)
    const values = formValues

    let customerSignature = customerSignatureRef.current.toDataURL() ?? ''
    let dealerSignature = dealerSignatureRef.current.toDataURL() ?? ''

    if(dealerSignature.length < 10) {
      dealerSignature = ""
    }

    if(customerSignature.length < 10) {
      customerSignature = ""
    }

    const customerInformation = {
      name: values.customer_name,
      // applicationNumber:
      phone: values.customer_phone,
      email: values.customer_email,
      shippingStreetAddress: values.customer_street_address,
      shippingStreetAddress2: values.customer_street_address_2,
      shippingCity: values.customer_city,
      shippingState: values.customer_state,
      shippingZip: values.customer_zip,
      welcomeLetterSent: false
    }

    const vehicleInformation = {
      VIN: values.vin,
      style: values.vehicle_style,
      make: values.vehicle_make,
      vehicleModel: values.vehicle_model,
      chassisModel: values.chassis_model,
      vehicleYear: values.vehicle_year,
      purchasePrice: values.purchase_price,
      lienholder: values.lienholder,
      purchaseDate: formatDate(values.purchase_date)
    }

    const contractInformation = {
      typeNew: values.vehicle_new_used === 'New' ? true : false,
      tier: values.warranty_tier,
      termLength: Number(values.term_length),
      deductible: Number(values.deductible.substring(1)),
      contractPrice: Number(values.service_contract_price),
      seniorCitizenMilitary: values.senior_military_discount.length === 1 ? true : false,
      originalInServiceDate: formatDate(values.original_in_service_date),
      originalWarrantyMonths: Number(values.original_warranty_length),
      customerSignature: customerSignature,
      dealerSignature: dealerSignature
    }
    try {
      const newCustomer = await Amplify.API.graphql({ query: mutations.createCustomer, variables: { input: customerInformation } })
      const newCustomerId = newCustomer.data.createCustomer.id

      const vehicleInput = { ...vehicleInformation, vehicleOwnerId: newCustomerId }
      const newVehicle = await Amplify.API.graphql({ query: mutations.createVehicle, variables: { input: vehicleInput } })
      const newVehicleId = newVehicle.data.createVehicle.id

      const contractInput = { ...contractInformation, contractVehicleId: newVehicleId, contractCustomerId: newCustomerId, contractDealerId: state.dealerId }
      const newContract = await Amplify.API.graphql({ query: mutations.createContract, variables: { input: contractInput } })
      const newContractId = newContract.data.createContract.id

      const customerInformationWithConnections = {
        id: newCustomerId,
        customerVehicleId: newVehicleId,
        customerContractId: newContractId
      }
      await Amplify.API.graphql({ query: mutations.updateCustomer, variables: { input: customerInformationWithConnections } })

      const vehicleInformationWithConnections = {
        id: newVehicleId,
        vehicleContractId: newContractId
      }
      await Amplify.API.graphql({ query: mutations.updateVehicle, variables: { input: vehicleInformationWithConnections } })

      const contractInformationWithConnections = {
        id: newContractId,
        contractVehicleId: newVehicleId
      }
      await Amplify.API.graphql({ query: mutations.updateContract, variables: { input: contractInformationWithConnections } })

      setFormComplete(true)
    } catch (e) {
      rollbar.log('customerInformation', customerInformation, 'vehicleInformation', vehicleInformation, 'contractInformation', contractInformation)
      rollbar.error('Errors submitting contract', e)
      rollbar.log('form values', values, customerSignature, dealerSignature)
    } finally {
      setIsLoading(false)
    }
  }

  const backToForm = () => {
    setReadyForSignatures(false)
  }

  const GoHome = () => {
    props.history.replace('/dealer')
  }

  if (formComplete === true) {
    return (
      <Container fluid className='p-0'>
        <h1 className='h3 mb-3'>New Warranty Application</h1>

        <Row>
          <Col>
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <div className='d-flex justify-content-center align-items-center w-100 p-5 h3'>Thank you. You have completed your application.</div>
                <div className='d-flex justify-content-center align-items-center w-100'>
                  <Button color='primary' size='lg' onClick={GoHome}>
                    Done
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
      {readyForSignatures && (
        <Container fluid className='p-0'>
          <h1 className='h3 mb-3'>New Warranty Application</h1>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle tag='h5' className='mb-0'>
                    Signatures
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-3 d-flex justify-content-center align-items-center'>
                        <div className='h4 p-5'>Customer Signature</div>
                      </div>
                      <div className='col-sm'>
                        <div className='d-flex justify-content-center align-items-center mb-5'>
                          <SignatureCanvas penColor='black' canvasProps={{ width: 750, height: 250, className: 'sigCanvas' }} ref={customerSignatureRef} />
                        </div>
                      </div>
                      <div className='w-100'></div>
                      <div className='col-3 d-flex justify-content-center align-items-center'>
                        <div className='h4 p-5'>Dealer Signature</div>
                      </div>
                      <div className='col-sm'>
                        <div className='d-flex justify-content-center align-items-center mb-5'>
                          <SignatureCanvas penColor='black' canvasProps={{ width: 750, height: 250, className: 'sigCanvas' }} ref={dealerSignatureRef} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center align-items-center w-100'>
                    <Button color='secondary' size='lg' onClick={backToForm} className='mr-3'>
                      Go Back
                    </Button>
                    <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading} onClick={submitApplication}>
                      Submit Contract Application
                    </LoadingButton>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      {!readyForSignatures && (
        <Container fluid className='p-0'>
          <h1 className='h3 mb-3'>New Warranty Application</h1>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle tag='h5' className='mb-0'>
                    Customer Information
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <AvForm className='mb-4' autoComplete='off' onSubmit={moveToSignatures} model={formValues}>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <AvField name='customer_name' label='Customer Name' errorMessage='Please enter your name' type='text' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='customer_phone' label='Phone Number' type='tel' required placeholder='(___)___-____' validate={{ tel: true }} />
                      </div>
                      <div className='col-12'>
                        <AvField name='customer_email' label='Email address' errorMessage='Please enter your email' type='email' required />
                      </div>
                      <div className='col-12'>
                        <AvField name='customer_street_address' label='Shipping Street Address' errorMessage='Please enter your address' type='text' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='customer_street_address_2' label='Street Address Line 2 (opt.)' type='text' />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='customer_city' label='City' errorMessage='Please enter your city' type='text' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='customer_state' label='State' errorMessage='Please enter your state' type='text' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='customer_zip' label='ZIP' type='text' inputMode='numeric' errorMessage='Please enter your ZIP code' required />
                      </div>
                    </div>{' '}
                    {/* / .row */}
                    {/* Divider */}
                    <hr className='mt-4 mb-5' />
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <AvField type='select' value='Toy Hauler' name='vehicle_style' label='Vehicle Style'>
                          <option>Toy Hauler</option>
                          <option>Fifth Wheel</option>
                          <option>Travel Trailer</option>
                          <option>Folding Camper</option>
                          <option>Slide-in Camper</option>
                        </AvField>
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='vehicle_year' label='Vehicle Year' errorMessage='Please enter your vehicle year' type='text' inputMode='numeric' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='vehicle_make' label='Vehicle Make' type='text' errorMessage='Please enter your vehicle make' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='vehicle_model' label='Vehicle Model' type='text' errorMessage='Please enter your vehicle model' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='chassis_model' label='Chassis Model' type='text' errorMessage='Please enter your chassis model' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='purchase_price' label='Purchase Price' type='number' errorMessage='Please enter your purchase price' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='purchase_date' label='Purchase Date' type='text' validate={{ date: { format: 'M/D/YYYY' } }} title='Use MM/DD/YYYY' placeholder='MM/DD/YYYY' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='lienholder' label='Lienholder' type='text' errorMessage='Please enter the vehicle lienholder' required />
                      </div>
                      <div className='col-12'>
                        <AvField name='vin' label='VIN' type='text' errorMessage='Please enter the vehicle VIN' required />
                      </div>
                    </div>
                    <hr className='mt-4 mb-5' />
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <AvField type='select' name='warranty_tier' label='Service Warranty Contract' onChange={(e) => setWarrantyTier(e.target.value)} value={warrantyTier}>
                          <option>Elite Elect</option>
                          <option>Elite Exclusive</option>
                          <option>Elite Evermore</option>
                        </AvField>
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField type='select' name='vehicle_new_used' label='Vehicle' defaultValue='New'>
                          <option>New</option>
                          <option>Used</option>
                        </AvField>
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField type='select' name='deductible' label='Deductible' onChange={(e) => setDeductible(e.target.value)} value={deductible}>
                          {deductibleOptions.map((opt) => {
                            return (
                              <option value={opt} key={`opt${opt}`}>
                                {opt}
                              </option>
                            )
                          })}
                        </AvField>
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='service_contract_price' label='Service Contract Price' type='number' errorMessage='Please enter your service contract price' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='term_length' label='Term (# of months)' type='number' errorMessage='Please enter the term length' required />
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvCheckboxGroup name='senior_military_discount' className='mt-3'>
                          <AvCheckbox label='Senior Citizen/Military' value='senior_military_discount' />
                        </AvCheckboxGroup>
                        <small className='form-text text-muted'>Are you a senior citizen or a member of the military? (Must show ID)</small>
                      </div>
                    </div>
                    <hr className='mt-4 mb-5' />
                    <div className='row'>
                      <div className='col-12 col-md-6 order-md-2'>
                        {/* Card */}
                        <div className='card bg-light border ml-md-4'>
                          <div className='card-body'>
                            <p className='mb-2'>New and Used Vehicles</p>
                            <p className='small text-muted mb-2'>Please detail the warranty information on your vehicle</p>
                            <ul className='small text-muted pl-4 mb-0'>
                              <li>The date the warranty went into effect</li>
                              <li>The duration of the warranty in months</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-md-6'>
                        <AvField name='original_in_service_date' label='Original In-Service Date' type='text' validate={{ date: { format: 'M/D/YYYY' } }} title='Use MM/DD/YYYY' />

                        <AvField name='original_warranty_length' label='Original Warranty Months' type='number' errorMessage='Please enter the length of your original warranty' />
                      </div>
                    </div>{' '}
                    <div className='row'></div> {/* / .row */}
                    {/* Divider */}
                    <hr className='mt-4 mb-5' />
                    <div className='row'>
                      <div className='col-12'>
                        {/* Card */}
                        <div className='card bg-light border ml-md-4'>
                          <div className='card-body'>
                            <p className='mb-2'>Contract Application Agreement</p>
                            <p className='small text-muted mb-2'>
                              Customer has reviewed a copy of the Service Warranty Contract. Customer also understands that this is an application for coverage; coverage is not legally binding or enforced until approved by Elite Service Warranty, LLC. We reserve the right to reject applications whatsoever. When approved, we will issue a registration page and contract to the customer. If you do not receive the registration page within sixty (60) days, you must call us at (866) 862-7164, or email us at customersupport@eliteservicewarranty.com or write to us 932 South Stapley DR, Mesa, AZ 85204.
                              Financing the vehicle does not require the purchase of a service warranty contract.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='text-danger text-center w-100'>{errorMessage}</div>
                      <button className='btn btn-block btn-primary mb-4 mt-4'>Sign Contract Application</button>
                    </div>{' '}
                    {/* / .row */}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  )
}

export default NewContract

function formatDate(date) {
  if (date.length > 1) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  } else {
    return null
  }
}
