import React, { useEffect, useState, useRef } from 'react'
import Amplify, { Auth, API } from 'aws-amplify'
import * as mutations from '../../../graphql/mutations'
import * as queries from '../../../graphql/queries'
import AdminService from '../../../services/admin'
// import Admin from '../../../../amplify/backend/function/AdminQueries7aa02296/src/cognitoActions'

import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'

import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import Timeline from '../../../components/Timeline'
import TimelineItem from '../../../components/TimelineItem'

import { MoreHorizontal, Edit2 } from 'react-feather'

import { useToasts } from 'react-toast-notifications'

import LoadingButton from '../../../components/LoadingButton'

import avatar1 from '../../../assets/img/avatars/avatar.jpg'
import avatar2 from '../../../assets/img/avatars/avatar-2.jpg'
import avatar3 from '../../../assets/img/avatars/avatar-3.jpg'
import avatar4 from '../../../assets/img/avatars/avatar-4.jpg'
import avatar5 from '../../../assets/img/avatars/avatar-5.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import WelcomeLetter from '../../../components/WelcomeLetter'

import moment from 'moment'

import Pdf from 'react-to-pdf'

const hash = require('object-hash')

const CustomersList = (props) => {
  const [allCustomers, setAllCustomers] = useState([])

  const getCustomers = async () => {
    const res = await API.graphql({ query: queries.listCustomers })
    let customers = res.data.listCustomers.items
    customers = customers.map((c) => {
      return { ...c, createdAt: moment(c.createdAt).format('MMM DD, YYYY') }
    })

    customers.sort((a,b) => (a.welcomeLetterSent > b.welcomeLetterSent) ? 1 : ((b.welcomeLetterSent > a.welcomeLetterSent) ? -1 : 0))

    setAllCustomers(customers)
  }

  useEffect(() => {
    getCustomers()
  }, [props.cacheKey])

  function booleanFormatter(cell, row, rowIndex, formatExtraData) {
    if (cell === false) {
      return <span className='badge badge-danger'>Not Sent</span>
    } else {
      return <span className='badge badge-success'>Sent</span>
    }
  }

  const columns = [
    {
      dataField: 'name',
      text: 'Customer Name'
    },
    {
      dataField: 'shippingCity',
      text: 'City'
    },
    {
      dataField: 'shippingState',
      text: 'State'
    },
    {
      dataField: 'createdAt',
      text: 'Created'
    },
    {
      dataField: 'welcomeLetterSent',
      text: 'Sent Welcome Letter',
      formatter: booleanFormatter,
      formatExtraData: {
        up: 'glyphicon glyphicon-chevron-up',
        down: 'glyphicon glyphicon-chevron-down'
      }
    }
  ]

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.setSelectedCustomer(row)
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'></CardTitle>
      </CardHeader>
      <CardBody>
        <ToolkitProvider keyField='id' data={allCustomers} columns={columns}>
          {(props) => (
            <>
              <BootstrapTable {...props.baseProps} rowEvents={rowEvents} pagination={paginationFactory()} striped hover condensed />
            </>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
}

const LetterComponent = (props) => {
  const letterRef = useRef()

  const markAsSent = async () => {
    await API.graphql({ query: mutations.updateCustomer, variables: { input: { id: props.customer.id, welcomeLetterSent: true } } })
    props.updateList()
    props.closeModal()
  }

  const markAsUnsent = async () => {
    await API.graphql({ query: mutations.updateCustomer, variables: { input: { id: props.customer.id, welcomeLetterSent: false } } })
    props.updateList()
    props.closeModal()
  }

  const goToCustomer = () => {
    props.history.push({
      pathname: `/admin/customers/${props.customer.id}`
    })
  }

  if (!props.isOpen) {
    return <></>
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.closeModal} contentClassName="welcomeLetterModal">
      <div className='m-3 d-flex justify-content-center'>
        <button className='btn btn-secondary mx-3' onClick={goToCustomer}>
          View Customer Details
        </button>

        {!props.customer.welcomeLetterSent && (
          <button className='btn btn-secondary mx-3' onClick={markAsSent}>
            Mark as Sent
          </button>
        )}

        {props.customer.welcomeLetterSent && (
          <button className='btn btn-danger mx-3' onClick={markAsUnsent}>
            Mark as Unsent
          </button>
        )}

        <Pdf targetRef={letterRef} filename={`${props.customer.name} welcome letter.pdf`}>
          {({ toPdf }) => (
            <button className='btn btn-primary' onClick={toPdf}>
              Generate Pdf
            </button>
          )}
        </Pdf>
      </div>
      <div ref={letterRef} style={{ width: "8.5in", height: "11in", backgroundColor: "white", padding:"1in", display: "flex",  justifyContent: "center", paddingLeft: "-0.3in", paddingTop: "0.3in", paddingBottom: "0.3in"}}>
        <WelcomeLetter customer={props.customer}/>
      </div>
    </Modal>
  )
}

const Customers = (props) => {
  const [cacheKey, setCacheKey] = useState()
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [welcomeLetterOpen, setWelcomeLetterOpen] = useState(false)

  const showWelcomeLetter = (customer) => {
    setSelectedCustomer(customer)
    setWelcomeLetterOpen(true)
    setCacheKey(hash(customer))
  }

  return (
    <>
      <Container fluid className='p-0'>
        <h1 className='h3 mb-3'>Welcome Letters</h1>

        <Row>
          <Col xl='12'>
            <CustomersList cacheKey={cacheKey} setSelectedCustomer={showWelcomeLetter} />
          </Col>
        </Row>

        <LetterComponent key={cacheKey} history={props.history} isOpen={welcomeLetterOpen} closeModal={() => setWelcomeLetterOpen(false)} updateList={() => setCacheKey(Math.random())} customer={selectedCustomer} />
      </Container>
    </>
  )
}

export default Customers
