import React, { useState } from "react";
import classNames from "classnames/bind";
import "./Coverage.css";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

import logo from "../../assets/img/esw-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross, faTimes } from "@fortawesome/free-solid-svg-icons";

const cx = classNames; //.bind(styles)

const CoveragePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="LandingPage">
        <div>
          {/* NAVBAR
    ================================================== */}
          <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
            <div className="container">
              <a className="navbar-brand" href="./index.html">
                <img src={logo} className="navbar-brand-img" alt="..." />
                <span className="navbar-brand-text">
                  Elite Service Warranty
                </span>
              </a>

              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink href="/coverage">Coverage</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dealer/signin">Dealers</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/contact">Contact Us</NavLink>
                  </NavItem>
                </Nav>
                <Link to="/customer/lookup" className="ml-auto">
                  <a className="navbar-btn btn btn-sm btn-primary lift">
                    Check my warranty
                  </a>
                </Link>
              </Collapse>
            </div>
          </nav>
          {/* WELCOME
    ================================================== */}
          <div>
            <section className="py-8 py-md-11 border-bottom">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-10 col-lg-8 text-center">
                    <img src={logo} className="header-image" />

                    <h1 className="display-2">Extended Service Protection</h1>

                    <p className="lead text-muted mb-7 mb-md-9">
                      Your Toy Hauler and Travel Trailer Service Specialists
                    </p>
                  </div>
                </div>{" "}
              </div>{" "}
            </section>

            <section className="pt-8 pt-md-0 border-bottom bg-black">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6 py-8 py-md-11 py-lg-12 order-md-1 order-2">
                    <div className="card p-5">
                      <div className="table-responsive">
                        <table className="table table-sm">
                          <thead className="">
                            <tr>
                              <th scope="col">Manufactured Component</th>
                              <th scope="col">Avg. Repair Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Air Condition/Roof</th>
                              <td>$550 - $2,250</td>
                            </tr>
                            <tr>
                              <th>Electric Step</th>
                              <td>$300 - $1,300</td>
                            </tr>
                            <tr>
                              <th>Refrigerator</th>
                              <td>$500 - $2,050</td>
                            </tr>
                            <tr>
                              <th>Water Heater</th>
                              <td>$300 - $1,500</td>
                            </tr>
                            <tr>
                              <th>Power Jack</th>
                              <td>$300 - $2,200</td>
                            </tr>
                            <tr>
                              <th>Slide Out</th>
                              <td>$300 - $3,500</td>
                            </tr>
                            <tr>
                              <th>Television</th>
                              <td>$100 - $1,000</td>
                            </tr>
                            <tr>
                              <th>Water Pump</th>
                              <td>$250 - $750</td>
                            </tr>
                            <tr>
                              <th>Furnace/Heater</th>
                              <td>$300 - $1,000</td>
                            </tr>
                            <tr>
                              <th>Microwave</th>
                              <td>$250 - $1,000</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-5 order-1 order-md-2 py-8 py-md-11 py-lg-12 d-flex flex-column justify-content-center ">
                    <h2 className="text-light">
                      Protect your investment and your recreation
                    </h2>

                    <p className="fs-lg text-gray-400 mb-6">
                      The risks of owning a toy hauler or travel trailer can be
                      significantly reduced by purchasing an Elite Service
                      Warranty Extended Service Protection
                    </p>
                  </div>
                </div>{" "}
              </div>{" "}
            </section>
            <section className="mt-12 mb-12">
              <div className="container">
                <h1 className="text-center mb-12">Covered Components</h1>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        <div className="d-flex justify-content-center mb-5">
                          <span className="h3 mb-0 mt-2">Elite Elect</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex justify-content-center mb-5">
                          <span className="h3 mb-0 mt-2">Elite Exclusive</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="d-flex justify-content-center mb-5">
                          <span className="h3 mb-0 mt-2">Elite Evermore</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <span>Auxiliary powerplant/generator assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          All internally lubricated parts of the power plant
                          engine, plus the starter motor, switches, generator
                          rotor and stator assembly, voltage regulator, PC
                          board, head and cylinder block (if damaged by the
                          failure of an internally lubricated part); and fuel
                          pump.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Boiler and water heater assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Burner assembly, tank, thermostat, thermocouple, gas
                          valve, electronic ignition Assembly, manually operated
                          switches, and PC board.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Brake assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Master Drums, master cylinder; hydraulic or electrical
                          brake actuator, and backing plates.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Electrical assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Factory installed 110/220V electrical system (except
                          any wiring, cords and reels), including breaker box
                          and breakers, outlets, power converter/inverter, and
                          auxiliary generator monitor panel.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Fresh water system assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Water pump, compressor, water tank, mounting brackets
                          and hardware, water lines, traps, fittings, and
                          faucets.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Heating system assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Furnace, ignitor, burner assembly, thermocouple, gas
                          valve, thermostat, blower motor, and PC board.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Landing jacks</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Landing jacks, leveling or stabilizing jacks, and lift
                          cranks
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>L.P. Gas system assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Regulators, gas bottles (except valves and gauges),
                          mounting brackets, pigtails, L.P. lines, and fittings.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Range and oven assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Gas, Electric, Convection, or Microwave units Burner
                          assembly, thermostat, thermocouple, burner valves,
                          ignition assembly, power hood, PC board, magnetron
                          tube, microwave touch pad, circulating fan and motor,
                          connections and fittings.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Refrigerator assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Thermostat, thermocouple, refrigerator/freezer cooling
                          unit, refrigerator icemaker, burner assembly, ignitor,
                          PC board, and eyebrow PC board.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Roof or basement air conditioning assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Compressor, condenser, evaporator, accumulator,
                          expansion valve, thermostat, receiver-dryer, blower
                          motor, high-low cut-off, switches, pressure cycling
                          switch, relays, fans, capacitors, and PC board.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Suspension assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Wheel bearings, spindles, axle shafts, and actuators.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Waste system assembly</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Shower, toilet, sink(s), holding tanks, mounting
                          brackets and hardware, gate valves, and connections
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Deluxe appliance coverage</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Covers all parts of stand-alone freezer, dishwasher,
                          washer/dryer, stand-alone icemaker, electric
                          fireplace, electrical component of a central vacuum
                          system, electrical awning components, electric fuel
                          station pump and trash compactor.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Jack system</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Factory installed, or factory-approved
                          dealer-installed, stationary leveling jack system –
                          coverage for stationary leveling jack system includes
                          all mechanical, electrical and hydraulic components.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Slide out room unit(s)</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Coverage for the slide out room unit(s) includes but
                          is not limited to the following: all mechanical,
                          electrical and hydraulic components, mounting brackets
                          and hardware, including generator slide out.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Powerstep system</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Coverage for powerstep and cover system includes all
                          mechanical, electrical and hydraulic components.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Entertainment package</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Covers all components of built-in electronic
                          audio/visual devices.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Mechanical seals and gaskets</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          Coverage for all components
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <span>Extended Coverage</span>
                        <br />
                        <small className="font-weight-normal mb-0 text-muted">
                          All component headings, factory installed mechanical,
                          hydraulic, electrical and electronic parts.
                        </small>
                      </th>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-danger-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faTimes} className="" />
                        </div>
                      </td>
                      <td>
                        <div className="badge badge-rounded-circle badge-success-soft mt-1 me-4 mr-1">
                          <FontAwesomeIcon icon={faCheck} className="" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h2 className="text-center mt-12">General Exclusions</h2>
                <small className="text-muted">
                  <ul>
                    <li>
                      Any component not originally supplied by manufacturer, or
                      not professionally replaced or installed. Any optional
                      components must professionally installed by a qualified RV
                      dealer or repair facility.
                    </li>
                    <li>
                      Mechanical Breakdowns outside the United States and
                      Canada.
                    </li>
                    <li>
                      Any breakdown on a unit used for off-road use, rental use,
                      hire or utilized in a business for profit, delivery
                      purposes, or by overloading the trailer beyond the maximum
                      GVW rating listed on the identification tag on the unit.
                    </li>
                  </ul>
                </small>

                <h2 className="text-center mt-12">Benefits</h2>
                <small className="text-muted">
                  <ul>
                    <li>
                      MILITARY/ SENIOR CITIZEN DISCOUNT: 50% off your deductible
                      (must present id’s for verification).
                    </li>
                    <li>
                      REDUCTION OF DEDUCTIBLE: 50% off your deductible for
                      repairs completed at seller/dealer.{" "}
                    </li>
                    <li>
                      EMERGENCY SERVICE: Up to $1000 coverage for emergency
                      service calls.
                    </li>
                  </ul>
                </small>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoveragePage;
