import React, { useEffect, useState } from 'react'
import Amplify, { Auth, API } from 'aws-amplify'
import * as mutations from '../../../graphql/mutations'
import * as queries from '../../../graphql/queries'
import AdminService from '../../../services/admin'
// import Admin from '../../../../amplify/backend/function/AdminQueries7aa02296/src/cognitoActions'

import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'

import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'

import Timeline from '../../../components/Timeline'
import TimelineItem from '../../../components/TimelineItem'

import { MoreHorizontal, Edit2 } from 'react-feather'

import { useToasts } from 'react-toast-notifications'

import LoadingButton from '../../../components/LoadingButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const hash = require('object-hash')

const DealersList = (props) => {
  const [allDealers, setAllDealers] = useState([])

  const getDealers = async () => {
    const res = await API.graphql({ query: queries.listDealers })
    setAllDealers(res.data.listDealers.items)
    console.log(res.data.listDealers.items)
  }

  useEffect(() => {
    getDealers()
  }, [props.cacheKey])

  const columns = [
    {
      dataField: 'name',
      text: 'Dealer'
    },
    {
      dataField: 'email',
      text: 'Email'
    },
    {
      dataField: 'phone',
      text: 'Phone'
    },
    {
      dataField: 'contact_person',
      text: 'Contact Person'
    },
    { dataField: 'createdAt', text: 'Created', hidden: true }
  ]

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.editDealerClick(row)
    }
  }

  const MySearch = (props) => {
    let input
    const handleClick = () => {
      props.onSearch(input.value)
    }
    return (
      <div className='d-flex mb-4 justify-content-center align-items-center'>
        <input className='form-control my-1 h-100' placeholder='Search here for a dealer' ref={(n) => (input = n)} type='text' />
        <button className='btn btn-primary ml-3 h-100' onClick={handleClick}>
          Search
        </button>
      </div>
    )
  }

  const ExportToCSVButton = (props) => {
    const handleClick = () => {
      props.onExport()
    }
    return (
      <div>
        <button className='btn btn-dark my-1 float-right' onClick={handleClick}>
          Export to CSV
        </button>
      </div>
    )
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'></CardTitle>
      </CardHeader>
      <CardBody>
        <ToolkitProvider
          keyField='id'
          data={allDealers}
          columns={columns}
          search
          exportCSV={{
            fileName: 'dealers.csv'
          }}>
          {(props) => (
            <>
              <MySearch {...props.searchProps} className='w-100' />
              <ExportToCSVButton {...props.csvProps} />
              <BootstrapTable {...props.baseProps} rowEvents={rowEvents} pagination={paginationFactory()} striped hover condensed />
            </>
          )}
        </ToolkitProvider>
      </CardBody>
    </Card>
  )
}

const NewDealerModal = (props) => {
  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const { addToast } = useToasts()

  const closeModal = () => {
    props.setIsOpen(false)
  }

  const addDealer = (event, errors, values) => {
    setIsLoading(true)
    if (errors.length > 0) {
      setIsLoading(false)
      setErrorMessage(`Please fix the errors and try again.`)
    } else {
      setErrorMessage('')
      createDealerAccount(values)
    }
  }

  const createDealerAccount = async (values) => {
    // const r = await AdminService.listUsers()
    // console.log(r)
    const dealer_attributes = [
      { Name: 'custom:contact_person', Value: values.dealer_contact },
      { Name: 'custom:phone_number', Value: values.dealer_phone },
      { Name: 'custom:dealer_name', Value: values.dealer_name },
      { Name: 'email', Value: values.dealer_email }
    ]

    try {
      await AdminService.createUser(values.dealer_email, dealer_attributes)
      await AdminService.addUserToGroup(values.dealer_email, 'Dealers')

      const dealerInformation = {
        name: values.dealer_name,
        email: values.dealer_email,
        phone: values.dealer_phone,
        contact_person: values.dealer_contact
      }

      const newDealer = await API.graphql({ query: mutations.createDealer, variables: { input: dealerInformation } })
      props.setIsOpen(false)
      setIsLoading(false)
      addToast('Dealer created successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setIsLoading(false)
    }

    props.setCacheKey(hash(values))
  }

  return (
    <Modal isOpen={props.isOpen}>
      <AvForm className='mb-4' autoComplete='off' onSubmit={addDealer}>
        <ModalHeader>Add a Dealer</ModalHeader>
        <ModalBody className='m-3'>
          <div className='p-3'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_name' label='Dealer Name' errorMessage='Please enter the name of the dealer' type='text' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_email' label='Dealer Email' errorMessage='Please enter the dealer contact email' type='email' required />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_phone' label='Dealer Phone' errorMessage='Please enter the phone number of the dealer' type='tel' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_contact' label='Dealer Contact' errorMessage='Please enter the name of the contact person' type='text' required />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='text-danger text-center w-100'>{errorMessage}</div>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading}>
            Add Dealer
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

const EditDealerModal = (props) => {
  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log('props.original_values', props.original_values)
  }, [props.original_values])

  const { addToast } = useToasts()

  const closeModal = () => {
    props.setIsOpen(false)
  }

  const editDealer = (event, errors, values) => {
    setIsLoading(true)
    if (errors.length > 0) {
      setIsLoading(false)
      setErrorMessage(`Please fix the errors and try again.`)
    } else {
      setErrorMessage('')
      editDealerAccount(values)
    }
  }

  const editDealerAccount = async (values) => {
    try {
      const dealer_attributes = [
        { Name: 'custom:contact_person', Value: values.dealer_contact },
        { Name: 'custom:phone_number', Value: values.dealer_phone },
        { Name: 'custom:dealer_name', Value: values.dealer_name },
        { Name: 'email', Value: values.dealer_email },
        { Name: 'email_verified', Value: 'true' }
      ]
      const res = await AdminService.updateUser(props.original_values.email, dealer_attributes)
      console.log(res)
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.message)
      setIsLoading(false)
    }

    try {
      const dealerInformation = {
        id: props.original_values.id,
        name: values.dealer_name,
        email: values.dealer_email,
        phone: values.dealer_phone,
        contact_person: values.dealer_contact
      }

      const editDealer = await API.graphql({ query: mutations.updateDealer, variables: { input: dealerInformation } })
      props.setIsOpen(false)
      setIsLoading(false)
      addToast('Dealer modified successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      const eMessage = error?.errors[0]?.message ?? "Error"
      setErrorMessage(eMessage)
    }

    props.setCacheKey(hash(values))
  }

  return (
    <Modal isOpen={props.isOpen}>
      <AvForm className='mb-4' autoComplete='off' onSubmit={editDealer}>
        <ModalHeader>Edit Dealer Information</ModalHeader>
        <ModalBody className='m-3'>
          <div className='p-3'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_name' label='Dealer Name' value={props.original_values.name} errorMessage='Please enter the name of the dealer' type='text' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_email' label='Dealer Email' value={props.original_values.email} errorMessage='Please enter the dealer contact email' type='email' required />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_phone' label='Dealer Phone' value={props.original_values.phone} errorMessage='Please enter the phone number of the dealer' type='tel' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='dealer_contact' label='Dealer Contact' value={props.original_values.contact_person} errorMessage='Please enter the name of the contact person' type='text' required />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='text-danger text-center w-100'>{errorMessage}</div>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading}>
            Edit Dealer
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

const Dealers = () => {
  const [addingDealerModalOpen, setAddingDealerModalOpen] = useState(false)
  const [editingDealerModalOpen, setEditingDealerModalOpen] = useState(false)
  const [editDealerValues, setEditDealerValues] = useState({})
  const [cacheKey, setCacheKey] = useState()

  const editDealerClick = (dealer) => {
    setEditDealerValues(dealer)
    setEditingDealerModalOpen(true)
  }

  return (
    <>
      <Container fluid className='p-0'>
        <Button color='primary' className='float-right mt-n1' onClick={() => setAddingDealerModalOpen(true)}>
          <FontAwesomeIcon icon={faPlus} /> New dealer
        </Button>
        <h1 className='h3 mb-3'>Dealers</h1>

        <Row>
          <Col xl='12'>
            <DealersList editDealerClick={editDealerClick} cacheKey={cacheKey} />
          </Col>
        </Row>
      </Container>
      <NewDealerModal isOpen={addingDealerModalOpen} setIsOpen={setAddingDealerModalOpen} setCacheKey={setCacheKey} />
      <EditDealerModal isOpen={editingDealerModalOpen} setIsOpen={setEditingDealerModalOpen} setCacheKey={setCacheKey} original_values={editDealerValues} />
    </>
  )
}

export default Dealers
