import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Amplify, { Auth, API } from "aws-amplify";
import * as queries from "../../../graphql/queries";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

import LoadingButton from "../../../components/LoadingButton";

// Auth.currentCredentials()
//   .then((d) => console.log('data: ', d))
//   .catch((e) => console.log('error: ', e))

const Lookup = (props) => {
  const [VINField, setVINField] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const lookupVIN = async () => {
    setIsLoading(true);
    setError("");

    if (VINField.length < 8) {
      setError("Please enter at least the last 8 characters of your VIN");
      setIsLoading(false);
      return;
    }

    const VINFilter = {
      VIN: {
        contains: VINField.toUpperCase(),
        // eq: VINField
      },
    };

    const currentUser = await Auth.currentUserInfo();
    if (!currentUser) {
      await Auth.signIn("guest@example.com", "guestuser");
    }

    let results = [];

    API.graphql({
      query: queries.listVehicles,
      variables: { filter: VINFilter, limit: 10000 },
    })
      .then((res) => {
        results = res.data.listVehicles.items;
      })
      .catch((res) => {
        console.log(res);
        results = res.data.listVehicles.items;
      })
      .finally(() => {
        setIsLoading(false);
        if (results.length === 0) {
          setError("No warranties found with this VIN");
        } else {
          const vehicle = results[0];
          props.history.push({
            pathname: `/customer/details/${vehicle.VIN}`,
            state: {
              customer: results,
            },
          });
        }
      });
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Customer Login</h2>
        <p className="lead">Please enter your VIN to see your warranty</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center"></div>
            <Form>
              <FormGroup>
                <Label>VIN</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="VIN"
                  placeholder="Enter the last 8 characters of your VIN"
                  value={VINField}
                  onChange={(e) => setVINField(e.target.value)}
                />
              </FormGroup>

              <div className="text-center mt-3">
                <div className="text-danger mb-2">{error}</div>
                <LoadingButton
                  loading={isLoading}
                  color={"primary"}
                  disabled={isLoading}
                  onClick={lookupVIN}
                >
                  Login
                </LoadingButton>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Lookup;
