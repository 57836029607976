import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => (
  <footer className='footer'>
    <Container fluid>
      <Row className='text-muted'>
        <Col xs='6' className='text-left'></Col>
        <Col xs='6' className='text-right'>
          <p className='mb-0'>
            &copy; 2021-{new Date().getFullYear()} -{' '}
            <span href='/' className='text-muted'>
              Elite Service Warranty
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
