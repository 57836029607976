import React, { useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import { Badge, Collapse } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

// import routes from '../routes/index'

import { AppContext } from '../State'
import { useContext } from 'react'

const routes = [
  {
    path: '/dealer/customers',
    name: 'Contracts',
    badgeColor: 'primary',
    badgeText: 0,
    containsHome: false
  },
  {
    path: '/dealer/new-contract',
    name: 'New Contract',
    badgeColor: 'primary',
    badgeText: 0,
    containsHome: false
  }
]

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */

  let _routes = {}

  // routes.forEach((route, index) => {
  //   const isActive = pathName.indexOf(route.path) === 0
  //   const isOpen = route.open
  //   const isHome = route.containsHome && pathName === '/' ? true : false

  //   _routes = Object.assign({}, _routes, { [index]: isActive || isOpen || isHome })
  // })

  return _routes
}

const SidebarItem = withRouter(({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
  const getSidebarItemClass = (path) => {
    return location.pathname === path ? 'active' : ''
  }

  return (
    <li className={'sidebar-item ' + getSidebarItemClass(to)}>
      <NavLink to={to} className='sidebar-link' activeClassName='active'>
        {Icon ? <Icon size={18} className='align-middle mr-3' /> : null}
        {name}
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} className='sidebar-badge'>
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  )
})

const Sidebar = ({ location, sidebar, layout }) => {
  const { state, dispatch } = useContext(AppContext)
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location))

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach((item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false })))

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }))
  }

  return (
    <nav className={'sidebar' + (!state.sidebarOpen ? ' toggled' : '')}>
      <div className='sidebar-content'>
        <PerfectScrollbar>
          <a className='sidebar-brand' href='/'>
            <span className='align-middle'>Elite Service Warranty</span>
          </a>

          <ul className='sidebar-nav'>
            {routes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  <SidebarItem name={category.name} to={category.path} icon={category.icon} badgeColor={category.badgeColor} badgeText={category.badgeText} />
                </React.Fragment>
              )
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default Sidebar
