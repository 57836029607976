import React from 'react'

export default function AppStylesWrapper(props) {
  return (
    <div className='AppStyles'>
      <div className='html'>
        <div className='body'>{props.children}</div>
      </div>
    </div>
  )
}
