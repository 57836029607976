import React, { useContext, useEffect, useState } from 'react'
import Amplify, { Auth, API, Storage } from 'aws-amplify'
import * as mutations from '../../../graphql/mutations'
import * as queries from '../../../graphql/queries'
import AdminService from '../../../services/admin'
// import Admin from '../../../../amplify/backend/function/AdminQueries7aa02296/src/cognitoActions'

import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, UncontrolledDropdown, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'

import Timeline from '../../../components/Timeline'
import TimelineItem from '../../../components/TimelineItem'

import { MoreHorizontal, Edit2 } from 'react-feather'

import { useToasts } from 'react-toast-notifications'

import LoadingButton from '../../../components/LoadingButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

import { AppContext } from '../../../State'

import Rollbar from 'rollbar'
const rollbar = new Rollbar({
  accessToken: 'dd58ad5a994d42aeac42eb02c0510aac',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production'
  }
})

const uniqid = require('uniqid')

const hash = require('object-hash')

const NewCustomerNoteModal = (props) => {
  const { state, dispatch } = useContext(AppContext)

  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [noteContent, setNoteContent] = useState()
  const [selectedFiles, setSelectedFiles] = useState()

  const { addToast } = useToasts()

  const closeModal = () => {
    props.setIsOpen(false)
  }

  const addCustomerNote = async () => {
    if (!noteContent || noteContent.length === 0) {
      props.setIsOpen(false)
      return
    }
    setIsLoading(true)

    const uploadFiles = selectedFiles.map((selectedFile) => {
      return new Promise(async (resolve, reject) => {
        const fileparts = selectedFile.name.split('.')
        const filename = fileparts[0]
        const extension = fileparts[1]
        const uniqueFilename = uniqid(filename + '-', '.' + extension)

        try {
          const fileresult = await Storage.put(uniqueFilename, selectedFile)
          resolve(fileresult.key)
        } catch (e) {
          console.log('failed', e)
          rollbar.error(e)
          reject(e)
        }
      })
    })

    const uploadedFiles = await Promise.all(uploadFiles)
    try {
      const noteInput = {
        customerNoteCustomerId: props.customer.id,
        note: noteContent,
        files: uploadedFiles,
        author: state.dealerName ?? 'Elite Service Warranty Admin',
        authorID: state.user.attributes.sub
      }

      const newNote = await API.graphql({ query: mutations.createCustomerNote, variables: { input: noteInput } })
      props.setIsOpen(false)
      setIsLoading(false)
      addToast('Note created successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setIsLoading(false)
      rollbar.error(error)
    }

    props.setCacheKey(Math.random())
  }

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files))
  }

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>Add Customer Note</ModalHeader>
      <ModalBody className='m-3'>
        <div className='p-0'>
          <Input type='textarea' name='text' value={noteContent} onChange={(e) => setNoteContent(e.target.value)} placeholder='Write your note here. Optionally, choose a file to upload below.' />
        </div>
        <div className='pt-2'>
          <input type='file' multiple onChange={handleFileChange} />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='text-danger text-center w-100'>{errorMessage}</div>
        <Button color='secondary' onClick={() => closeModal()}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          color={'primary'}
          disabled={isLoading}
          onClick={() => {
            addCustomerNote()
          }}>
          Add Note
        </LoadingButton>
      </ModalFooter>
    </Modal>
  )
}

const EditCustomerModal = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [deductibleOptions, setDeductibleOptions] = useState([])
  const [deductible, setDeductible] = useState()
  const [warrantyTier, setWarrantyTier] = useState('Elite Elect')
  const [seniorCitizenMilitaryChecked, setSeniorCitizenMilitaryChecked] = useState(false)

  useEffect(() => {
    let deductibleOpts = []
    if (warrantyTier === 'Elite Elect') {
      deductibleOpts = ['$100', '$200', '$500']
    } else {
      deductibleOpts = ['$200', '$500']
    }
    setDeductibleOptions(deductibleOpts)
    // setDeductible(deductibleOpts[0])
  }, [warrantyTier])

  useEffect(() => {
    console.log('props.original_values', props.original_values)
    setWarrantyTier(props.original_values.contract?.tier)
    setDeductible(`$${props.original_values.contract?.deductible}`)
    setSeniorCitizenMilitaryChecked(props.original_values.contract?.seniorCitizenMilitary)
  }, [props.original_values])

  const { addToast } = useToasts()

  const closeModal = () => {
    props.setIsOpen(false)
  }

  const editCustomer = (event, errors, values) => {
    setIsLoading(true)
    if (errors.length > 0) {
      setIsLoading(false)
      setErrorMessage(`Please fix the errors and try again.`)
    } else {
      setErrorMessage('')
      editCustomerInfo(values)
    }
  }

  const editCustomerInfo = async (values) => {
    console.log('VALUES', values)

    try {
      const customerInformation = {
        id: props.original_values.id,
        name: values.customer_name,
        email: values.customer_email,
        phone: values.customer_phone,
        shippingStreetAddress: values.customer_street_address,
        shippingStreetAddress2: values.customer_street_address_2,
        shippingCity: values.customer_city,
        shippingState: values.customer_state,
        shippingZip: values.customer_zip
      }
      const originalCustomer = Object.keys(customerInformation).reduce((accumulator, key) => {
        accumulator[key] = props.original_values[key]
        return accumulator
      }, {})
      const customerDifference = deepDiffMapper.map(customerInformation, originalCustomer)

      const vehicleInformation = {
        id: props.original_values.vehicle?.id,
        VIN: values.vin,
        style: values.vehicle_style,
        make: values.vehicle_make,
        vehicleModel: values.vehicle_model,
        chassisModel: values.chassis_model,
        vehicleYear: values.vehicle_year,
        purchasePrice: values.purchase_price,
        lienholder: values.lienholder,
        purchaseDate: formatDate(values.purchase_date)
      }
      const originalVehicle = Object.keys(vehicleInformation).reduce((accumulator, key) => {
        accumulator[key] = props.original_values.vehicle[key]
        return accumulator
      }, {})
      const vehicleDifference = deepDiffMapper.map(vehicleInformation, originalVehicle)

      const contractInformation = {
        id: props.original_values.contract?.id,
        typeNew: values.vehicle_new_used === 'New' ? true : false,
        tier: values.warranty_tier,
        termLength: Number(values.term_length),
        deductible: Number(values.deductible.substring(1)),
        contractPrice: Number(values.service_contract_price),
        seniorCitizenMilitary: values.senior_military_discount.length === 1 ? true : false,
        originalInServiceDate: formatDate(values.original_in_service_date),
        originalWarrantyMonths: Number(values.original_warranty_length)
      }
      const originalContract = Object.keys(contractInformation).reduce((accumulator, key) => {
        accumulator[key] = props.original_values.contract[key]
        return accumulator
      }, {})
      const contractDifference = deepDiffMapper.map(contractInformation, originalContract)

      const allDifferences = { ...customerDifference, ...vehicleDifference, ...contractDifference }

      let AnnotatedDifferences = Object.keys(allDifferences).map((d) => {
        const o = allDifferences[d]
        if (o.type === 'updated') {
          return `Updated ${d} from ${o.previous_data} to ${o.data}`
        }
        if (o.type === 'deleted') {
          return `Deleted ${d} (${o.data})`
        }
      })

      AnnotatedDifferences = AnnotatedDifferences.filter((e) => e)
      AnnotatedDifferences = AnnotatedDifferences.join('\n')

      const updatedCustomer = await Amplify.API.graphql({ query: mutations.updateCustomer, variables: { input: customerInformation } })
      const updatedVehicle = await Amplify.API.graphql({ query: mutations.updateVehicle, variables: { input: vehicleInformation } })
      const updatedContract = await Amplify.API.graphql({ query: mutations.updateContract, variables: { input: contractInformation } })

      const noteInput = {
        customerNoteCustomerId: props.customer.id,
        note: AnnotatedDifferences,
        author: state.dealerName ?? 'Elite Service Warranty Admin',
        authorID: state.user.attributes.sub
      }

      const newNote = await API.graphql({ query: mutations.createCustomerNote, variables: { input: noteInput } })

      props.setIsOpen(false)
      setIsLoading(false)
      addToast('Customer modified successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      setErrorMessage(error.errors[0].message)
    }

    props.setCacheKey(hash(values))
  }

  return (
    <Modal isOpen={props.isOpen} contentClassName='welcomeLetterModal'>
      <AvForm className='mb-4' autoComplete='off' onSubmit={editCustomer}>
        <ModalHeader>Edit Dealer Information</ModalHeader>
        <ModalBody className='m-3'>
          <div className='p-3'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField name='customer_name' label='Customer Name' value={props.original_values.name} errorMessage='Please enter your name' type='text' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='customer_phone' label='Phone Number' value={props.original_values.phone} type='tel' required placeholder='(___)___-____' validate={{ tel: true }} />
              </div>
              <div className='col-12'>
                <AvField name='customer_email' label='Email address' value={props.original_values.email} errorMessage='Please enter your email' type='email' required />
              </div>
              <div className='col-12'>
                <AvField name='customer_street_address' value={props.original_values.shippingStreetAddress} label='Shipping Street Address' errorMessage='Please enter your address' type='text' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='customer_street_address_2' value={props.original_values.shippingStreetAddress2} label='Street Address Line 2 (opt.)' type='text' />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='customer_city' label='City' value={props.original_values.shippingCity} errorMessage='Please enter your city' type='text' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='customer_state' label='State' value={props.original_values.shippingState} errorMessage='Please enter your state' type='text' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='customer_zip' label='ZIP' type='text' value={props.original_values.shippingZip} inputMode='numeric' errorMessage='Please enter your ZIP code' required />
              </div>
            </div>{' '}
            {/* / .row */}
            {/* Divider */}
            <hr className='mt-4 mb-5' />
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField type='select' value={props.original_values.vehicle?.style} name='vehicle_style' label='Vehicle Style'>
                  <option>Toy Hauler</option>
                  <option>Fifth Wheel</option>
                  <option>Travel Trailer</option>
                  <option>Folding Camper</option>
                  <option>Slide-in Camper</option>
                </AvField>
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='vehicle_year' value={props.original_values.vehicle?.vehicleYear} label='Vehicle Year' errorMessage='Please enter your vehicle year' type='text' inputMode='numeric' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='vehicle_make' value={props.original_values.vehicle?.make} label='Vehicle Make' type='text' errorMessage='Please enter your vehicle make' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='vehicle_model' value={props.original_values.vehicle?.vehicleModel} label='Vehicle Model' type='text' errorMessage='Please enter your vehicle model' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='chassis_model' value={props.original_values.vehicle?.chassisModel} label='Chassis Model' type='text' errorMessage='Please enter your chassis model' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='purchase_price' value={props.original_values.vehicle?.purchasePrice} label='Purchase Price' type='number' errorMessage='Please enter your purchase price' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='purchase_date' value={moment(props.original_values.vehicle?.purchaseDate, 'YYYY-MM-DD').format('MM/DD/YYYY')} label='Purchase Date' type='text' validate={{ date: { format: 'M/D/YYYY' } }} title='Use MM/DD/YYYY' placeholder='MM/DD/YYYY' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='lienholder' value={props.original_values.vehicle?.lienholder} label='Lienholder' type='text' errorMessage='Please enter the vehicle lienholder' required />
              </div>
              <div className='col-12'>
                <AvField name='vin' value={props.original_values.vehicle?.VIN} label='VIN' type='text' errorMessage='Please enter the vehicle VIN' required />
              </div>
            </div>
            <hr className='mt-4 mb-5' />
            <div className='row'>
              <div className='col-12 col-md-6'>
                <AvField type='select' name='warranty_tier' label='Service Warranty Contract' onChange={(e) => setWarrantyTier(e.target.value)} value={warrantyTier}>
                  <option>Elite Elect</option>
                  <option>Elite Exclusive</option>
                  <option>Elite Evermore</option>
                </AvField>
              </div>
              <div className='col-12 col-md-6'>
                <AvField type='select' name='vehicle_new_used' label='Vehicle' value={props.original_values.contract?.typeNew === true ? 'New' : 'Used'}>
                  <option>New</option>
                  <option>Used</option>
                </AvField>
              </div>
              <div className='col-12 col-md-6'>
                <AvField type='select' name='deductible' label='Deductible' onChange={(e) => setDeductible(e.target.value)} value={deductible}>
                  {deductibleOptions.map((opt) => {
                    return (
                      <option value={opt} key={`opt${opt}`}>
                        {opt}
                      </option>
                    )
                  })}
                </AvField>
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='service_contract_price' value={props.original_values.contract?.contractPrice} label='Service Contract Price' type='number' errorMessage='Please enter your service contract price' required />
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='term_length' value={props.original_values.contract?.termLength} label='Term (# of months)' type='number' errorMessage='Please enter the term length' required />
              </div>
              <div className='col-12 col-md-6'>
                {/*  */}
                <AvCheckboxGroup name='senior_military_discount' className='mt-3'>
                  <AvCheckbox label='Senior Citizen/Military' value='senior_military_discount' checked={seniorCitizenMilitaryChecked} onChange={(e) => setSeniorCitizenMilitaryChecked(e.target.value.checked)} />
                </AvCheckboxGroup>
                <small className='form-text text-muted'>Are you a senior citizen or a member of the military? (Must show ID)</small>
              </div>
            </div>
            <hr className='mt-4 mb-5' />
            <div className='row'>
              <div className='col-12 col-md-6 order-md-2'>
                {/* Card */}
                <div className='card bg-light border ml-md-4'>
                  <div className='card-body'>
                    <p className='mb-2'>New Vehicles Only</p>
                    <p className='small text-muted mb-2'>Please detail the warranty information on your new vehicle</p>
                    <ul className='small text-muted pl-4 mb-0'>
                      <li>The date the warranty went into effect</li>
                      <li>The duration of the warranty in months</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <AvField name='original_in_service_date' value={props.original_values.contract?.originalInServiceDate ? moment(props.original_values.contract?.originalInServiceDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : null} label='Original In-Service Date' type='text' validate={{ date: { format: 'M/D/YYYY' } }} title='Use MM/DD/YYYY' />

                <AvField name='original_warranty_length' value={props.original_values.contract?.originalWarrantyMonths} label='Original Warranty Months' type='number' errorMessage='Please enter the length of your original warranty' />
              </div>
            </div>{' '}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='text-danger text-center w-100'>{errorMessage}</div>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading}>
            Edit Customer Information
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  )
}

const SingleCustomer = (props) => {
  const { state, dispatch } = useContext(AppContext)
  const selectedCustomer = props.selectedCustomer

  let customerNotes = selectedCustomer?.notes?.items?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))

  const deleteNote = async (noteId) => {
    const noteInput = {
      id: noteId
    }
    await API.graphql({ query: mutations.deleteCustomerNote, variables: { input: noteInput } })
    props.setCacheKey(Math.random())
  }

  const getProtectedFile = async (filename) => {
    const signedURL = await Storage.get(filename)
    window.open(signedURL, '_blank')
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h5' className='mb-0'>
          {selectedCustomer.name}
          <Button
            color='primary'
            className='float-right mt-n1'
            onClick={() => {
              props.setAddNoteModal(true)
            }}>
            <FontAwesomeIcon icon={faPlus} className='mt-n1' /> Add note
          </Button>
          <Button
            color='primary'
            className='float-right mt-n1 mr-3'
            onClick={() => {
              props.editCustomerClick()
            }}>
            <FontAwesomeIcon icon={faEdit} className='mt-n1' /> Edit customer
          </Button>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Table size='sm' className='my-1'>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{selectedCustomer.name}</td>
            </tr>
            <tr>
              <th>Phone</th>
              <td>{selectedCustomer.phone}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{selectedCustomer.email}</td>
            </tr>
            <tr>
              <th>Shipping Address</th>
              <td>
                {selectedCustomer.shippingStreetAddress} {selectedCustomer.shippingStreetAddress2}
              </td>
            </tr>
            <tr>
              <th>City</th>
              <td>{selectedCustomer.shippingCity}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{selectedCustomer.shippingState}</td>
            </tr>
            <tr>
              <th>ZIP</th>
              <td>{selectedCustomer.shippingZip}</td>
            </tr>
            <tr>
              <th>User Created</th>
              <td>{moment(selectedCustomer.createdAt).format('MMM DD, YYYY')}</td>
            </tr>
            <tr>
              <th>Welcome Letter</th>
              <td>
                {selectedCustomer.welcomeLetterSent === true && <span className='badge badge-success'>Sent</span>}
                {!selectedCustomer.welcomeLetterSent && <span className='badge badge-danger'>Not Sent</span>}
              </td>
            </tr>
            <tr>
              <th>Dealership</th>
              <td>
                {selectedCustomer.contract.dealer?.name}
                <br />
                Email: {selectedCustomer.contract.dealer?.email}
                <br />
                Phone: {selectedCustomer.contract.dealer?.phone}
              </td>
            </tr>
            <tr>
              <th>Vehicle</th>
              <td>
                VIN: {selectedCustomer.vehicle.VIN}
                <br />
                Style: {selectedCustomer.vehicle.style}
                <br />
                Make: {selectedCustomer.vehicle.make}
                <br />
                Model: {selectedCustomer.vehicle.vehicleModel}
                <br />
                Year: {selectedCustomer.vehicle.vehicleYear}
                <br />
                Chassis: {selectedCustomer.vehicle.chassisModel}
                <br />
                Year: {selectedCustomer.vehicle.vehicleYear}
                <br />
                Purchase Price: ${selectedCustomer.vehicle.purchasePrice}
                <br />
                Purchase Date: {selectedCustomer.vehicle.purchaseDate}
                <br />
                Lienholder: {selectedCustomer.vehicle.lienholder}
              </td>
            </tr>
            <tr>
              <th>Contract Terms</th>
              <td>
                {/* {selectedCustomer.contract.approved === true && <span className='badge badge-success'>Approved</span>}
                {!selectedCustomer.contract.approved && <span className='badge badge-danger'>Not Approved</span>}
                <br /> */}
                Tier: {selectedCustomer.contract.tier}
                <br />
                {selectedCustomer.contract.typeNew ? 'New' : 'Used'} vehicle
                <br />
                Deductible: ${selectedCustomer.contract.deductible}
                <br />
                Service Contract Price: ${selectedCustomer.contract.contractPrice}
                <br />
                Contract Date: {moment(selectedCustomer.contract.createdAt).format('MMM DD, YYYY')}
                <br />
                Term: {selectedCustomer.contract.termLength} months
                <br />
                Senior Citizen/Military: {selectedCustomer.contract.seniorCitizenMilitary ? 'Yes' : 'No'}
                {selectedCustomer.contract.typeNew && (
                  <>
                    <br />
                    Original In-Service Date: {selectedCustomer.contract.originalInServiceDate}
                    <br />
                    Original Warranty: {selectedCustomer.contract.originalWarrantyMonths} months
                  </>
                )}
                <br />
                <img src={selectedCustomer.contract.customerSignature} style={{ maxHeight: '50px' }} />
                <br />
                <img src={selectedCustomer.contract.dealerSignature} style={{ maxHeight: '50px' }} />
              </td>
            </tr>
          </tbody>
        </Table>

        <hr />

        <strong>Customer Activity</strong>

        <Timeline className='mt-2'>
          {customerNotes &&
            customerNotes.map((note) => {
              return (
                <TimelineItem key={note.id}>
                  <strong>{note.author}</strong>
                  <div className='d-flex float-right text-muted text-sm'>
                    {moment(note.createdAt).fromNow()}
                    <div className='ml-2 btn p-0' onClick={() => deleteNote(note.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </div>
                  <p>{note.note}</p>
                  <div>
                    {note.files &&
                      note.files.map((file) => {
                        return (
                          <div key={file} className='noteFile' onClick={() => getProtectedFile(file)}>
                            {file}
                          </div>
                        )
                      })}
                  </div>
                </TimelineItem>
              )
            })}
        </Timeline>
      </CardBody>
    </Card>
  )
}

const CustomerDetail = (props) => {
  const [selectedCustomer, setSelectedCustomer] = useState(props.location?.state?.customer)
  const [addingNoteModalOpen, setAddingNoteModalOpen] = useState(false)
  const [editingCustomerModalOpen, setEditingCustomerModalOpen] = useState(false)
  const [editCustomerValues, setEditCustomerValues] = useState({})
  const [cacheKey, setCacheKey] = useState()

  const pullCustomer = async (customer_id) => {
    const res = await API.graphql({ query: queries.getCustomer, variables: { id: customer_id } })
    const customer = res.data.getCustomer
    setSelectedCustomer(customer)
    setEditCustomerValues(customer)
  }

  const editCustomerClick = () => {
    setEditCustomerValues(selectedCustomer)
    setEditingCustomerModalOpen(true)
  }

  useEffect(() => {
    // if (!props.location.state?.customer || Object.keys(props.location.state.customer).length === 0) {
    const customer_id = props.match.params.id
    pullCustomer(customer_id)
    // }
  }, [cacheKey])

  return (
    <>
      <Container fluid className='p-0'>
        <div className='btn btn-primary mb-3' onClick={() => props.history.goBack()}>
          Back to customer list
        </div>

        <Row>
          <Col xl='12'>
            {!selectedCustomer && <></>}
            {selectedCustomer && <SingleCustomer selectedCustomer={selectedCustomer} setAddNoteModal={setAddingNoteModalOpen} editCustomerClick={editCustomerClick} setCacheKey={setCacheKey} />}
          </Col>
        </Row>
      </Container>
      <NewCustomerNoteModal isOpen={addingNoteModalOpen} setIsOpen={setAddingNoteModalOpen} customer={selectedCustomer} setCacheKey={setCacheKey} />
      <EditCustomerModal isOpen={editingCustomerModalOpen} setIsOpen={setEditingCustomerModalOpen} customer={selectedCustomer} setCacheKey={setCacheKey} original_values={editCustomerValues} />
    </>
  )
}

export default CustomerDetail

function formatDate(date) {
  if (date.length > 1) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  } else {
    return null
  }
}

var deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.'
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1,
          previous_data: obj1 === undefined ? obj1 : obj2
        }
      }

      var diff = {}
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue
        }

        var value2 = undefined
        if (obj2[key] !== undefined) {
          value2 = obj2[key]
        }

        diff[key] = this.map(obj1[key], value2)
      }
      for (var key in obj2) {
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue
        }

        diff[key] = this.map(undefined, obj2[key])
      }

      return diff
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED
      }
      return this.VALUE_UPDATED
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === '[object Function]'
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === '[object Array]'
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === '[object Date]'
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === '[object Object]'
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x)
    }
  }
})()
