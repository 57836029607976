import React, { useEffect, useState } from "react";
import Amplify, { Auth, API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import * as customQueries from "../../../graphql/custom-queries";
import AdminService from "../../../services/admin";
// import Admin from '../../../../amplify/backend/function/AdminQueries7aa02296/src/cognitoActions'

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";

import Timeline from "../../../components/Timeline";
import TimelineItem from "../../../components/TimelineItem";

import { MoreHorizontal, Edit2 } from "react-feather";

import { useToasts } from "react-toast-notifications";

import LoadingButton from "../../../components/LoadingButton";

import avatar1 from "../../../assets/img/avatars/avatar.jpg";
import avatar2 from "../../../assets/img/avatars/avatar-2.jpg";
import avatar3 from "../../../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../../assets/img/avatars/avatar-5.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

const hash = require("object-hash");

const CustomersList = (props) => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const getCustomers = async (searchQuery) => {
    const res = await API.graphql({
      query: customQueries.listCustomersNoSignatures,
      variables: { limit: 10000 },
    });
    let customers = res.data.listCustomers.items;
    customers = customers.map((c) => {
      return {
        ...c,
        createdAt: moment(c.createdAt).format("MMM DD, YYYY"),
        purchaseDate: moment(c.vehicle.purchaseDate).format("MMM DD, YYYY"),
      };
    });
    setAllCustomers(customers);
  };

  useEffect(() => {
    getCustomers();
  }, [props.cacheKey]);

  const columns = [
    {
      dataField: "name",
      text: "Customer Name",
    },
    {
      dataField: "shippingCity",
      text: "City",
    },
    {
      dataField: "shippingState",
      text: "State",
    },
    {
      dataField: "purchaseDate",
      text: "Purchase Date",
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const a2 = moment(a).format("x");
        const b2 = moment(b).format("x");
        if (order === "asc") return a2 - b2;
        else return b2 - a2;
      },
    },
    {
      dataField: "vehicle.VIN",
      text: "Vehicle VIN",
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.setSelectedCustomer(row);
    },
  };

  const MySearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };
    return (
      <div className="d-flex mb-4 justify-content-center align-items-center">
        <input
          className="form-control my-1 h-100"
          placeholder="Search here for a contract, customer, or VIN"
          ref={(n) => (input = n)}
          type="text"
        />
        <button className="btn btn-primary ml-3 h-100" onClick={handleClick}>
          Search
        </button>
      </div>
    );
  };

  return (
    <Card>
      <CardBody>
        {allCustomers.length === 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner color="primary" />{" "}
            <div className="h5 pl-3 m-0">Loading customer list</div>
          </div>
        )}
        {allCustomers.length > 0 && (
          <ToolkitProvider
            keyField="id"
            data={allCustomers}
            columns={columns}
            search
          >
            {(props) => (
              <>
                <MySearch {...props.searchProps} className="w-100" />
                <BootstrapTable
                  {...props.baseProps}
                  rowEvents={rowEvents}
                  pagination={paginationFactory()}
                  striped
                  hover
                  condensed
                  sort={{ dataField: "createdAt", order: "desc" }}
                />
              </>
            )}
          </ToolkitProvider>
        )}
      </CardBody>
    </Card>
  );
};

const Customers = (props) => {
  const [cacheKey, setCacheKey] = useState();

  useEffect(() => {
    (async function () {
      const u = await Auth.currentSession();
      console.log("user", u);
    })();
  }, []);

  const showCustomerDetail = (customer) => {
    props.history.push({
      pathname: `/dealer/customers/${customer.id}`,
      state: {
        customer: customer,
      },
    });
  };

  return (
    <>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Contracts</h1>

        <Row>
          <Col xl="12">
            <CustomersList
              cacheKey={cacheKey}
              setSelectedCustomer={showCustomerDetail}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Customers;
