import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { Button, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap'

import LoadingButton from '../../../components/LoadingButton'

import { useToasts } from 'react-toast-notifications'

import Rollbar from 'rollbar'
const rollbar = new Rollbar({
  accessToken: 'dd58ad5a994d42aeac42eb02c0510aac',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production'
  }
})

const ResetPassword = (props) => {
  const [email, setEmail] = useState()
  const [confirmationCode, setConfirmationCode] = useState()
  const [newPassword, setNewPassword] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [awaitingConfirmationCode, setAwaitingConfirmationCode] = useState(false)
  const [error, setError] = useState()

  const { addToast } = useToasts()

  const resetPassword = () => {
    setIsLoading(true)
    setError('')
    Auth.forgotPassword(email)
      .then((r) => {})
      .catch((e) => {
        setError(e.message)
        rollbar.error(e)
      })
      .finally(() => {
        setIsLoading(false)
        setAwaitingConfirmationCode(true)
      })
  }

  const confirmResetPassword = () => {
    setIsLoading(true)
    setError('')
    Auth.forgotPasswordSubmit(email, confirmationCode, newPassword)
      .then((r) => {
        addToast('Password reset successfully', {
          appearance: 'success',
          autoDismiss: true
        })
        props.history.push('/admin/dashboard')
      })
      .catch((e) => {
        setError(e.message)
        rollbar.error(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (awaitingConfirmationCode === true) {
    return (
      <React.Fragment>
        <div className='text-center mt-4'>
          <h1 className='h2'>Confirm Password Reset</h1>
          <p className='lead'>Enter the verification code sent to your email.</p>
        </div>

        <Card>
          <CardBody>
            <div className='m-sm-4'>
              <Form>
                <FormGroup>
                  <Label>Verification Code</Label>
                  <Input key='confirmationCodeInput' bsSize='lg' type='text' value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} placeholder='Enter your verification code' />
                </FormGroup>
                <FormGroup>
                  <Label>New Password</Label>
                  <Input key='newPasswordInput' bsSize='lg' type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='Enter your new password' />
                </FormGroup>
                <div className='text-center mt-3'>
                  <div className='text-danger mb-2'>{error}</div>
                  <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading} onClick={confirmResetPassword}>
                    Reset password
                  </LoadingButton>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className='text-center mt-4'>
        <h1 className='h2'>Reset password</h1>
        <p className='lead'>Enter your email to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className='m-sm-4'>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input bsSize='lg' type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' />
              </FormGroup>
              <div className='text-center mt-3'>
                <div className='text-danger mb-2'>{error}</div>
                <LoadingButton loading={isLoading} color={'primary'} disabled={isLoading} onClick={resetPassword}>
                  Reset password
                </LoadingButton>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ResetPassword
