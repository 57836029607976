import React, { useEffect, useState } from "react";
import Amplify, { Auth, API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import AdminService from "../../../services/admin";
// import Admin from '../../../../amplify/backend/function/AdminQueries7aa02296/src/cognitoActions'

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

import moment from "moment";

const SingleCustomer = (props) => {
  const selectedCustomer = props.selectedCustomer;

  return (
    <Card>
      <CardBody>
        <div className="w-100">
          <h3>Vehicle</h3>
          <Table size="sm" className="my-1 mb-5" responsive>
            <tbody>
              <tr>
                <th scope="row">Vehicle VIN</th>
                <td>{selectedCustomer.VIN}</td>
              </tr>
              <tr>
                <th scope="row">Style</th>
                <td>{selectedCustomer.style}</td>
              </tr>
              <tr>
                <th scope="row">Make</th>
                <td>{selectedCustomer.make}</td>
              </tr>
              <tr>
                <th scope="row">Model</th>
                <td>{selectedCustomer.vehicleModel}</td>
              </tr>
              <tr>
                <th scope="row">Year</th>
                <td>{selectedCustomer.vehicleYear}</td>
              </tr>
              <tr>
                <th scope="row">Chassis</th>
                <td>{selectedCustomer.chassisModel}</td>
              </tr>
              <tr>
                <th scope="row">Purchase Price</th>
                <td>{selectedCustomer.purchasePrice}</td>
              </tr>
              <tr>
                <th scope="row">Purchase Date</th>
                <td>{selectedCustomer.purchaseDate}</td>
              </tr>
              <tr>
                <th scope="row">Lienholder</th>
                <td>{selectedCustomer.lienholder}</td>
              </tr>
            </tbody>
          </Table>

          <h3>Contract Terms</h3>
          <Table size="sm" className="my-1" responsive>
            <tbody>
              <tr>
                <th scope="row" style={{ maxWidth: "35%" }}>
                  Tier
                </th>
                <td>{selectedCustomer.contract?.tier}</td>
              </tr>
              <tr>
                <th scope="row">Vehicle Condition</th>
                <td>
                  {selectedCustomer.contract?.typeNew ? "New" : "Used"} vehicle
                </td>
              </tr>
              <tr>
                <th scope="row">Deductible</th>
                <td>{selectedCustomer.contract?.deductible}</td>
              </tr>
              <tr>
                <th scope="row">Service Contract Price</th>
                <td>{selectedCustomer.contract?.contractPrice}</td>
              </tr>
              <tr>
                <th scope="row">Contract Date</th>
                <td>
                  {moment(selectedCustomer.contract?.createdAt).format(
                    "MMM DD, YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">Term</th>
                <td>{selectedCustomer.contract?.termLength} months</td>
              </tr>
              <tr>
                <th scope="row">Senior Citizen/Military</th>
                <td>
                  {selectedCustomer.contract?.seniorCitizenMilitary
                    ? "Yes"
                    : "No"}
                </td>
              </tr>

              {selectedCustomer.contract?.typeNew && (
                <>
                  <tr>
                    <th scope="row">Original In-Service Date</th>
                    <td>{selectedCustomer.contract?.originalInServiceDate}</td>
                  </tr>
                  <tr>
                    <th scope="row">Original Warranty</th>
                    <td>
                      {selectedCustomer.contract?.originalWarrantyMonths} months
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>

          <div className="w-100 d-flex justify-content-center align-items-center">
            <img
              src={selectedCustomer.contract?.customerSignature}
              style={{ maxHeight: "100px" }}
            />
            <img
              src={selectedCustomer.contract?.dealerSignature}
              style={{ maxHeight: "100px" }}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const CustomerDetail = (props) => {
  const [selectedCustomer, setSelectedCustomer] = useState(
    props.location?.state?.customer
  );

  const pullCustomer = async (vin) => {
    const VINFilter = {
      VIN: {
        eq: vin,
      },
    };

    let results = [];

    API.graphql({
      query: queries.listVehicles,
      variables: { filter: VINFilter, limit: 10000 },
    })
      .then((res) => {
        results = res.data.listVehicles.items;
      })
      .catch((res) => {
        results = res.data.listVehicles.items;
      })
      .finally(() => {
        console.log(results);
        if (results.length === 0) {
          setSelectedCustomer(null);
          props.history.replace("/customer");
        } else {
          const vehicle = results[0];
          setSelectedCustomer(vehicle);
        }
      });
  };

  useEffect(() => {
    // if (!props.location.state?.customer || Object.keys(props.location.state.customer).length === 0) {
    const vin = props.match.params.vin;
    pullCustomer(vin);
    // }
  }, []);

  return (
    <>
      <Container fluid className="p-0">
        <h1>Your Warranty</h1>

        <Row>
          <Col xl="12">
            {!selectedCustomer && <></>}
            {selectedCustomer && (
              <SingleCustomer selectedCustomer={selectedCustomer} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerDetail;
