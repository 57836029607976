import React, { useContext, useEffect } from 'react'

import Wrapper from '../components/Wrapper'
import Sidebar from '../components/CustomerSidebar'
import Main from '../components/Main'
import Navbar from '../components/Navbar'
import Content from '../components/Content'
import Footer from '../components/Footer'

import { AppContext } from '../State'

const Dashboard = ({ children, history }) => {
  const { state, dispatch } = useContext(AppContext)

  return (
    <React.Fragment>
      <Wrapper>
        <Sidebar />
        <Main>
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  )
}

export default Dashboard
