/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:5457fae3-ee3a-4846-940c-9152aca15550",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_iQgLTyRGc",
    "aws_user_pools_web_client_id": "403f6at6f8jrlvdipgm6p91580",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gttnvkep2zee7nqr3fyo2hnebu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://qsjnyo2gs0.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "esw-files133359-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
