import React, { useState } from "react";
import classNames from "classnames/bind";
import "./LandingCustom.css";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

import logo from "../../assets/img/esw-logo.png";
import heroBackground from "../../assets/img/herorv.jpg";

const cx = classNames; //.bind(styles)

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="LandingPage">
        <div>
          {/* NAVBAR
    ================================================== */}
          <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
            <div className="container">
              {/* Brand */}
              <a className="navbar-brand" href="./index.html">
                <img src={logo} className="navbar-brand-img" alt="..." />
                <span className="navbar-brand-text">
                  Elite Service Warranty
                </span>
              </a>
              {/* Toggler */}
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <NavLink href="/coverage">Coverage</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/dealer/signin">Dealers</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/contact">Contact Us</NavLink>
                  </NavItem>
                </Nav>
                <Link to="/customer/lookup" className="ml-auto">
                  <a className="navbar-btn btn btn-sm btn-primary lift">
                    Check my warranty
                  </a>
                </Link>
              </Collapse>
              {/* Collapse */}
            </div>
          </nav>

          {/* WELCOME
    ================================================== */}
          <section
            data-jarallax
            data-speed=".8"
            className="d-flex align-items-center pb-5"
            style={{
              height: "100vh",
              backgroundImage:
                "linear-gradient(0.3turn, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.10)), url(assets/img/photos/hero2.jpg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-6">
                  {/* <div className="w-100 text-center text-md-left">
                    <img
                      src={logo}
                      className="w-50 ml-0 ml-md-6 mb-2"
                      alt="..."
                    />
                  </div> */}

                  {/* Heading */}
                  <h1 className="display-2 text-center text-md-left text-white font-weight-bold">
                    Take comfort in knowing you're covered
                  </h1>
                  {/* Text */}
                  <p className="lead text-white mb-6 mb-md-8">
                    Keeping your RV operational, on the road, and in the field.
                  </p>

                  <Link to="/coverage" className="ml-auto">
                    <a className="navbar-btn btn btn-sm btn-light px-12 lift">
                      Learn more
                    </a>
                  </Link>
                </div>
              </div>{" "}
              {/* / .row */}
            </div>{" "}
            {/* / .container */}
          </section>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
